<template>
  <div>
    <a-modal class="modal-view"
             v-model:open="visible"
             :maskClosable="false"
             :afterClose="cancel"
             :destroyOnClose="true"
             :width="380"
             @cancel="cancel">
      <template #title>
        <div class="cu-center">TRX兑换</div>
      </template>
      <a-spin :spinning="loading">
        <div class="padding-top-xs">
          <div>当前兑换比例 ：1 USDT = <span class="text-blue">{{ trxInfo.rate }}</span> TRX</div>
          <div>当前可兑余额：<span class="text-blue">{{ trxInfo.balanceUsdt }} </span> USDT</div>
          <div class="margin-top-xs cu-center text-bold">USDT-TRC20地址</div>
          <div class="cu-center">
            <a-qrcode :size="240" :value="trxInfo.address" :bordered="false"/>
          </div>
          <div class="text-blue">{{ trxInfo.address }} <span class="margin-left-xs cursor-pointer" @click="$Copy({text: trxInfo.address})"><CopyOutlined/></span></div>
          <div class="margin-top-sm text-gray">1.上述地址点击可复制,地址尾号: db51</div>
          <div class="mt-1 text-gray">2.进U即兑，全自动返TRX,一笔一回,<span class="text-blue">20</span> U起兑,兑换不可超过可兑余额</div>
          <div class="mt-1 text-gray">3.请不要使用交易所转账,丢失不负责</div>
          <div class="mt-1 text-red">注:交易经过多次网络确认,两分钟内到账！</div>
          <div class="margin-top-xs cursor-pointer text-blue" @click="toCus">
            ❥遇到问题:[联系客服]
          </div>
        </div>
      </a-spin>
      <template #footer>
        <div class="cu-center">
          <div class="pay-btn cursor-pointer cu-center" @click="onFinish">确认</div>
        </div>
      </template>
    </a-modal>
  </div>
</template>


<script>
import { exchangeTrx } from '@/api/api'
import { CopyOutlined } from '@ant-design/icons-vue'

export default {
  components: {
    CopyOutlined
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    visible: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.exchangeTrxQuery()
        }
      }
    }
  },
  data() {
    return {
      trxInfo: {
        rate: 0.00,
        balanceUsdt: 0,
        address: 'TMxURRnpsCvNPhJcJjUBkrq92WaH3bdb51',
      },
      loading: false,
    }
  },
  methods: {
    toCus() {
      let routeUrl = this.$router.resolve({
        path: '/ChatIm',
        query: { //要传的参数 可传多个
          dbMerId: 0
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    exchangeTrxQuery() {
      this.loading = true
      exchangeTrx()
          .then(res => {
            this.trxInfo = res.data
            this.loading = false
          })
          .catch(err => {
            this.$Message.error(err)
          })
    },
    cancel() {
      this.trxInfo = {
        rate: 0.00,
        balanceUsdt: 0,
        address: 'TMxURRnpsCvNPhJcJjUBkrq92WaH3bdb51',
      }
      this.$emit('close', '')
    },
    onFinish() {
      this.trxInfo = {
        rate: 0.00,
        balanceUsdt: 0,
        address: 'TMxURRnpsCvNPhJcJjUBkrq92WaH3bdb51',
      }
      this.$emit('close', '')
    },
  }
}
</script>
<style scoped lang="scss">

</style>