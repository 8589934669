<template>
  <div>
    <div class="sign-up">
      <!-- 顶部logo -->
      <div class="top-content">
        <div class="logo-box">
          <div class="left">
            <img
                src="../assets/index/logo.png"
                class="logo-img"
                alt=""
                @click="$router.push({path:'/'})"
            />
            <div class="register">{{ $t('login.l_00001') }}</div>
          </div>

          <div class="to-login">
            <a-button type="link" @click="toRegister">{{ $t('login.l_00002') }}</a-button>
          </div>
        </div>
      </div>
      <div class="login-center">
        <div class="login-container">
          <div>
            <a-image
                style="border-top-left-radius: 24px !important;border-bottom-left-radius: 24px !important;"
                :width="480"
                :height="471"
                :preview="false"
                :src="require('@/assets/index/login-banner.jpg')"
                :fallback="require('@/assets/index/logo.png')"
            />
          </div>
          <div class="container-right">
            <a-tabs v-model:activeKey="activeKey" @change="changeActive">
              <a-tab-pane key="1" :tab="$t('login.l_00003')">
                <div style="padding: 0">
                  <a-form @finish="submit" :model="formRegist">
                    <a-form-item name="email"
                                 :rules="[{ required: true, message: '请输入邮箱' },{ type: 'email', message: '请输入正确的邮箱' }]">
                      <a-input class="input-email" @input="$InputEvent.OnlyNumericLetters($event)"
                               v-no-chinese
                               @change="errorLogin = ''"
                               :placeholder="$t('login.l_00004')" v-model:value="formRegist.email">
                        <template #prefix>
                          <Icon type="ios-mail-outline" size="22" color="#B2B2B2"/>
                        </template>
                      </a-input>
                    </a-form-item>

                    <a-form-item name="password" :rules="[{ required: true, message: '请输入密码' }]">
                      <a-input-password class="input-captcha" @input="$InputEvent.OnlyNumericLetters($event)"
                                        :placeholder="$t('login.l_00005')"
                                        v-no-chinese
                                        @change="errorLogin = ''"
                                        v-model:value="formRegist.password">
                        <template #prefix>
                          <Icon type="ios-lock-outline" size="22" color="#B2B2B2"/>
                        </template>
                      </a-input-password>
                    </a-form-item>

                    <a-form-item name="isAgree" :rules="[{ required: true, message: '请阅读并同意' }]">
                      <a-form-item-rest>
                        <div class="d-flex justify-end text-gray">
                          <a-button type="link" size="small" @click="toForget">{{ $t('login.l_00006') }}</a-button>
                        </div>
                      </a-form-item-rest>
                      <privacy :isAgree="formRegist.isAgree" @onChange="ChangePrivacy"/>
                    </a-form-item>

                    <a-form-item>
                      <div v-if="errorLogin" class="text-red mb-2">
                        {{ errorLogin }}
                      </div>
                      <a-button class="bottom-pane" style="width: 100%" type="primary" html-type="submit">
                        <a-spin size="mini" :spinning="spinningLoading">
                          {{ $t('login.l_00007') }}
                        </a-spin>
                      </a-button>
                    </a-form-item>

                    <a-form-item>
                      <a-divider style="margin-top: -10px;"><span class="three-login">{{ $t('login.l_00013') }}</span>
                      </a-divider>
                      <div class="three-login-view">
                        <a-space wrap :size="30">
                          <a-popover trigger="hover" placement="bottom">
                            <template #content>
                              <a-spin size="mini" :spinning="spinningLoading">
                                <div class="telegram">
                                  <telegram id="telegram-login" :mode="'callback'" :userpic="false"
                                            :telegram-login="telegramBot"
                                            @callback="yourCallbackFunction">
                                  </telegram>
                                </div>
                              </a-spin>
                            </template>
                            <a-avatar size="32" :src="require('@/assets/login/dianbao.png')"/>
                          </a-popover>
                          <div style="display: flex;justify-content: center;align-items: center;">
                            <a-spin size="mini" :spinning="spinningLoading">
                              <a-avatar size="32" :src="require('@/assets/login/goggle.png')" @click="login"/>
                            </a-spin>
                          </div>
                        </a-space>
                      </div>
                    </a-form-item>
                  </a-form>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" :tab="$t('login.l_00008')">
                <div style="padding: 0">
                  <a-form>
                    <a-form-item name="phone">
                      <a-input class="input-phone" :placeholder="$t('login.l_00009')"
                               @input="$InputEvent.OnlyNumeric($event)"
                               v-in-number
                               v-model:value="formRegist.phone" @change="errorLogin = ''">
                        <template #prefix>
                          <Vue3CountryIntl
                              schema="popover"
                              :selectedText="$t('pwd.pwd_018')"
                              :useChinese="true"
                              :iso2="formRegist.iso2"
                              :searchInputPlaceholder="$t('common.com_002')"
                              v-model="formRegist.countryCode"
                              v-model:visible="countryView"
                              @change="changeCountry">
                            <Vue3CountryFlag :value="formRegist.iso2"
                                             :iso2="formRegist.iso2"
                                             @click="countryView = !countryView">
                              <template v-slot="{country}">
                                <span style="margin-left: 3px;">{{ '+' + country.dialCode }}</span>
                                <Icon type="md-arrow-dropdown"/>
                              </template>
                            </Vue3CountryFlag>
                          </Vue3CountryIntl>
                          <a-divider type="vertical" style="margin-left: -6px;"/>
                        </template>
                      </a-input>
                    </a-form-item>

                    <a-form-item name="password">
                      <a-input-password class="input-captcha"
                                        @change="errorLogin = ''"
                                        @input="$InputEvent.OnlyNumericLetters($event)"
                                        :placeholder="$t('login.l_00010')"
                                        v-no-chinese
                                        v-model:value="formRegist.password">
                        <template #prefix>
                          <Icon type="ios-lock-outline" size="22" color="#B2B2B2"/>
                        </template>
                      </a-input-password>
                    </a-form-item>

                    <a-form-item>
                      <div class="d-flex justify-end text-gray">
                        <a-button type="link" size="small" @click="toForget">{{ $t('login.l_00006') }}</a-button>
                      </div>
                      <privacy :isAgree="formRegist.isAgree" @onChange="ChangePrivacy"/>
                    </a-form-item>

                    <a-form-item>
                      <div v-if="errorLogin" class="text-red mb-2">
                        {{ errorLogin }}
                      </div>
                      <a-button class="bottom-pane" style="width: 100%" type="primary" @click="submit">
                        <a-spin size="mini" :spinning="spinningLoading">
                          {{ $t('login.l_00012') }}
                        </a-spin>
                      </a-button>
                    </a-form-item>

                    <a-form-item>
                      <a-divider style="margin-top: -10px;"><span class="three-login">{{ $t('login.l_00013') }}</span>
                      </a-divider>
                      <div class="three-login-view">
                        <a-space wrap :size="30">
                          <a-popover trigger="hover" placement="bottom">
                            <template #content>
                              <a-spin size="mini" :spinning="spinningLoading">
                                <div class="telegram">
                                  <telegram id="telegram-login" :mode="'callback'" :userpic="false"
                                            :telegram-login="telegramBot"
                                            @callback="yourCallbackFunction">
                                  </telegram>
                                </div>
                              </a-spin>
                            </template>
                            <a-avatar size="32" :src="require('@/assets/login/dianbao.png')"/>
                          </a-popover>
                          <div style="display: flex;justify-content: center;align-items: center;">
                            <a-spin size="mini" :spinning="spinningLoading">
                              <a-avatar size="32" :src="require('@/assets/login/goggle.png')" @click="login"/>
                            </a-spin>
                          </div>
                        </a-space>
                      </div>
                    </a-form-item>
                  </a-form>
                </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>
        <div class="diccc">
          <div class="customer cursor-pointer" @click="showCusService">
            <div class="font-top">
              {{ $t('login.l_00014') }}
            </div>
            <img
                src="../assets/index/cou.png"
                class="cou-img"
                alt=""
                @click="showCusService"
            />
            <div class="font-bom">
              <div>
                <Icon type="ios-headset-outline" size="18"/>
                {{ $t('login.l_00014') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部栏 -->
    <BaseFooter></BaseFooter>

    <a-modal
        v-model:open="modal2Visible"
        :title="$t('login.l_00015')"
        :okText="$t('login.l_00016')"
        :cancelText="$t('login.l_00017')"
        centered
        @ok="loginAuth">
      <a-form layout="vertical">
        <a-form-item label="">
          <a-input class="input-captcha" v-model:value="formRegist.invitationCode" maxlength="6"
                   :placeholder="$t('login.l_00018')"/>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import BaseFooter from '@/components/footer/Footer.vue'
import { Debounce } from '@/utils/validate'
import { checkThirdParty, getUserInfo, loginEmail, loginOauth, mobilePassword } from '@/api/user'
import privacy from '@/components/privacy.vue'
import telegram from '@/components/telegram/vue-telegram-login.vue'
import { googleTokenLogin } from 'vue3-google-login'
import { OnlyNumeric, OnlyNumericLetters } from '@/utils/inputEvent'

export default {
  name: 'Login',
  components: {
    BaseFooter,
    privacy,
    telegram,
  },
  data() {
    return {
      spinningLoading: false,
      modal2Visible: false,
      activeKey: '1',
      formRegist: {
        email: '',
        phone: '',
        password: '',
        iso2: 'us',
        countryCode: '1',
        invitationCode: '',
        isAgree: true
      },
      countryView: false,
      config: require('@/config'),
      year: new Date().getFullYear(),
      accountInfo: {},
      tgLoginData: {},
      oanth2Data: {},
      telegramBot: 'danbao51_bot',
      errorLogin: ''
    }
  },
  created() {
    let href = window.location.href
    if (href.startsWith('https://51danbao.com')) {
      this.telegramBot = 'dbw51_2_bot'
    } else if (href.startsWith('https://pc.51danbao.com')) {
      this.telegramBot = 'dbw51_3_bot'
    } else {
      this.telegramBot = 'danbao51_bot'
    }
  },
  methods: {
    OnlyNumeric,
    OnlyNumericLetters,
    showCusService() {
      let routeUrl = this.$router.resolve({
        path: '/ChatIm',
        query: { //要传的参数 可传多个
          dbMerId: 0
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    login() {
      this.spinningLoading = true
      googleTokenLogin()
          .then((response) => {
            this.oanth2Data = {
              account: response.access_token,
              userName: '0',
              avatar: '0',
              uniqueIdent: '0',
              authType: 0,
            }
            checkThirdParty(this.oanth2Data)
                .then(res => {
                  if (res.code === 200) {
                    if (res.data === 'true') {
                      // 弹出邀请码
                      this.spinningLoading = false
                      this.formRegist.invitationCode = ''
                      this.modal2Visible = true
                    }
                  } else {
                    this.oath2Login('')
                  }
                })
          })
          .catch(err => {
            this.spinningLoading = false
          })
    },
    loginAuth() {
      this.spinningLoading = true
      this.oath2Login(this.formRegist.invitationCode)
    },
    yourCallbackFunction(event) {
      this.spinningLoading = true
      this.getValueSDB(event)
    },
    getValueSDB(e) {
      this.tgLoginData = e
      this.oanth2Data = {
        account: this.tgLoginData.username,
        uniqueIdent: this.tgLoginData.id,
        userName: this.tgLoginData.first_name,
        avatar: this.tgLoginData.photo_url,
        authType: 1,
      }
      // 是否打开邀请码
      // 判定此电报是否登录过
      let data = {
        account: this.oanth2Data.account,
        uniqueIdent: this.oanth2Data.uniqueIdent
      }
      checkThirdParty(data)
          .then(res => {
            if (res.code === 200) {
              if (res.data === 'true') {
                // 弹出邀请码
                this.formRegist.invitationCode = ''
                this.modal2Visible = true
                this.spinningLoading = false
              }
            } else {
              // 直接登录
              this.oath2Login('')
            }
            this.spinningLoading = false
          })
    },
    oath2Login(e) {
      let postdata = {
        account: this.oanth2Data.account,
        uniqueIdent: this.oanth2Data.uniqueIdent,
        userName: this.oanth2Data.userName,
        invitationCode: e,
        avatar: this.oanth2Data.avatar,
        authType: this.oanth2Data.authType,
      }
      loginOauth(postdata)
          .then(res => {
            if (res.code === 200) {
              let data = res.data
              this.$store.commit('LOGIN', { 'token': data.token })
              if (data.isNew && data.newPeopleCouponList && data.newPeopleCouponList.length !== 0) {
                localStorage.setItem('newGift', data.newPeopleCouponList)
              } else {
                localStorage.removeItem('newGift')
              }
              this.oanth2Data = ''
              this.getUserInfo(data)
              this.spinningLoading = false
            } else {
              this.$Message.error(res.message)
              this.spinningLoading = false
            }
          })
          .catch(e => {
            this.spinningLoading = false
          })
    },
    ChangePrivacy(e) {
      this.formRegist.isAgree = e
      this.errorLogin = ''
    },
    changeActive(e) {
      this.activeKey = e
      this.formRegist.isAgree = true
      this.errorLogin = ''
    },
    toForget() {
      this.$router.push({
        path: '/forgetPassword',
      })
    },
    submit: Debounce(function () {
      let that = this
      this.errorLogin = ''
      if (!this.formRegist.isAgree) return this.errorLogin = that.$t('login.l_00020')

      if (this.activeKey === '1') {
        if (!that.formRegist.password) return this.errorLogin = that.$t('login.l_00019')
        if (!that.formRegist.email) return this.errorLogin = that.$t('login.l_00021')
        if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/i.test(that.formRegist.email)) return this.errorLogin = that.$t('login.l_00022')

        this.spinningLoading = true
        loginEmail({
          email: that.formRegist.email,
          password: that.formRegist.password,
        })
            .then(res => {
              if (res.code === 200) {
                that.$store.commit('LOGIN', { 'token': res.data.token })
                that.getUserInfo(res.data)
                this.errorLogin = ''
              } else {
                this.spinningLoading = false
                this.errorLogin = res.message
              }
            })
            .catch(e => {
            })
      }

      if (this.activeKey === '2') {
        if (!that.formRegist.password) return this.errorLogin = that.$t('login.l_00019')
        if (!that.formRegist.phone) return this.errorLogin = that.$t('login.l_00023')
        this.spinningLoading = true
        mobilePassword({
          'countryCode': '' + that.formRegist.countryCode,
          'password': that.formRegist.password,
          'phone': that.formRegist.phone
        })
            .then(res => {
              if (res.code === 200) {
                let data = res.data
                this.$store.commit('LOGIN', { 'token': res.data.token })
                that.getUserInfo(data)
                this.errorLogin = ''
              } else {
                this.spinningLoading = false
                this.errorLogin = res.message
              }
            })
            .catch(err => {
              this.spinningLoading = false
            })
      }
    }),
    getUserInfo(data) {
      this.accountInfo = data
      if (data.id) {
        if (this.activeKey === '1' && this.formRegist.email && this.formRegist.password) {
          this.saveAccount()
        }
        if (this.activeKey === '2' && this.formRegist.phone && this.formRegist.password) {
          this.saveAccount()
        }
        this.trueGetUserInfo()
      }
    },
    saveAccount() {
      // if (this.activeKey === '1' && this.formRegist.email && this.formRegist.password) {
      //   this.$Cookie.set('rememberEmail', this.formRegist.email)
      //   this.$Cookie.set('rememberEmailPassword', this.formRegist.password)
      // }
      // if (this.activeKey === '2' && this.formRegist.phone && this.formRegist.password) {
      //   this.$Cookie.set('rememberCountryCode', this.formRegist.countryCode)
      //   this.$Cookie.set('rememberPhone', this.formRegist.phone)
      //   this.$Cookie.set('rememberPhonePassword', this.formRegist.password)
      // }
    },
    trueGetUserInfo() {
      this.$store.commit('SETUID', this.accountInfo.id)
      getUserInfo()
          .then(res => {
            this.accountInfo = res.data
            this.$store.commit('VISITOR_USER_ID', this.accountInfo.id)
            this.$store.commit('UPDATE_USERINFO', res.data)
            this.$router.replace({ path: '/' })
            this.spinningLoading = false
          })
          .catch(e => {
            this.spinningLoading = false
          })
    },
    changeCountry(event) {
      this.formRegist.iso2 = event.iso2
      this.formRegist.countryCode = event.dialCode
    },
    toRegister() {
      this.$router.push({
        path: '/SignUp',
      })
    },
  },
  mounted() {
    if (window.innerHeight > 1050) {
      document.querySelector('.sign-up').style.height = (window.innerHeight - 330) + 'px'
    } else {
      document.querySelector('.sign-up').style.height = '750px'
    }
  },
  watch: {},
}
</script>
<style scoped lang="scss">
.imgBorder {
  border: 30px solid #0f1011;
}

:deep(.nsm7Bb-HzV7m-LgbsSe-Bz112c) {
  padding: 0 !important;
  height: 22px !important;
  margin-left: 4px !important;
  min-width: 22px !important;
  width: 22px !important;
}

.telegram {
  display: flex;
  justify-content: center;
  align-items: center;
}

.three-login-view {
  display: flex;
  justify-content: center;
  align-items: center;
}

.three-login {
  font-size: 12px;
  color: #999999;
}

.send-btn {
  display: flex;
  justify-content: flex-end;

  margin-bottom: 15px;
}

.forget-font {
  color: #5D5D5D;
}


:deep(.vue3-yn-country-flag .country-flag-img) {
  width: 30px;
}

.input-email {
  width: 100%;
  height: 50px;
  border-radius: 12px;

  border: 1px solid rgba(102, 102, 102, 0.35);
}

.input-captcha {
  width: 100%;
  height: 50px;
  border-radius: 12px;

  border: 1px solid rgba(102, 102, 102, 0.35);
}

.bottom-pane {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  opacity: 1;

  background: #269FFA;
}

.input-phone {
  width: 100%;
  height: 50px;
  border-radius: 12px;

  border: 1px solid rgba(102, 102, 102, 0.35);

}

.login-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;

  .diccc {
    height: 473px;
    margin-left: 142px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

    .customer {
      position: relative;
      width: 136px;
      height: 204px;
      border-radius: 10px;
      opacity: 1;
      box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;

      .font-top {
        padding: 10px 0;
        font-family: Source Han Sans;
        font-size: 20px;
        font-weight: bold;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0px;
        color: #269FFA;
      }

      .cou-img {
        width: 91.81px;
        height: 91.31px;
      }

      .font-bom {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 42px;
        border-radius: 0px 0px 10px 10px;
        opacity: 1;
        background: #269FFA;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        font-family: Source Han Sans;
        font-size: 14px;
        font-weight: normal;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0px;
        color: #FFFFFF;
      }
    }

  }

  .login-container {
    width: 992px;
    height: 473px;
    border-radius: 24px;
    border: 1px solid #F4F4F4;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .container-right {
      width: 510px;
      height: 473px;
      padding: 20px 50px 0 50px;
      position: relative;

      .bottom-check {
        position: absolute;
        bottom: 20px;
        left: 50px;
      }
    }
  }
}

.top-content {
  width: 100%;
  height: 80px;
  position: relative;
  z-index: 1;

  box-shadow: 0 1px 1px #ddd;

  .to-login {
    font-family: HarmonyOS Sans;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #269FFA;

    button {
      color: #269FFA;
    }
  }

  .logo-img {
    max-width: 150px;
    cursor: pointer;
    margin-right: 32px;
  }

  .logo-box {
    width: 80%;
    max-width: 1200px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;

    .left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: nowrap;

      .register {
        font-family: HarmonyOS Sans;
        font-size: 16px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0px;
        color: #999999;
      }

      img {
        width: 150px;
        cursor: pointer;
      }

      div {
        font-size: 20px;
      }
    }
  }
}

.logo-box {
  width: 600px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  img {
    width: 150px;
    cursor: pointer;
  }

  div {
    font-size: 20px;
    margin-top: 10px;
  }
}

.verify-con {
  position: absolute;
  left: 140px;
  top: 80px;
  z-index: 10;
}

.other-login {
  margin: 0 auto;

  .ivu-icon {
    font-size: 24px;
  }
}

.regist {
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;

  span {
    margin-left: 10px;

    &:hover {
      cursor: pointer;
      color: #F31947;
    }
  }
}

.foot {
  position: fixed;
  bottom: 4vh;
  width: 368px;
  left: calc(50% - 184px);
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;

  .help {
    margin: 0 auto;
    margin-bottom: 1vh;
    width: 60%;

    .item {
      color: rgba(0, 0, 0, 0.45);
    }

    :hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
</style>

