<template>
  <div class="wrapper">
    <div class="mb_10 box" v-if="!homePage">
      <div class="top-center">
        <div style="width:1200px">
          <a-tabs v-model:activeKey="activeKey" @change="changeOrderStatus">
            <a-tab-pane key="-1">
              <template #tab>全部订单</template>
            </a-tab-pane>
            <a-tab-pane key="0">
              <template #tab>待付款</template>
            </a-tab-pane>
            <a-tab-pane key="1">
              <template #tab>待发货</template>
            </a-tab-pane>
            <a-tab-pane key="4">
              <template #tab>待收货</template>
            </a-tab-pane>
            <a-tab-pane key="6">
              <template #tab>已完成</template>
            </a-tab-pane>
            <a-tab-pane key="9">
              <template #tab>已取消</template>
            </a-tab-pane>
            <a-tab-pane key="-10">
              <template #tab>
                <a-badge :count="evaluateCount">
                  待评价
                </a-badge>
              </template>
            </a-tab-pane>
            <a-tab-pane key="-11">
              <template #tab>售后/退款</template>
            </a-tab-pane>
          </a-tabs>
        </div>
        <div style="position: absolute;right:2px;top:16px;" v-if="activeKey !== '-10'">
          <Input style="width: 350px;"
                 search
                 enter-button
                 v-model="params.keywords"
                 @on-search="getList"
                 placeholder="请输入订单号搜索"/>
        </div>
      </div>
    </div>
    <a-spin size="large" :spinning="spinShow">
      <!-- 订单列表 -->
      <div v-if="activeKey !== '-10' && activeKey !== '-11'">
        <empty v-if="orderList.length === 0"/>
        <a-card v-else :bordered="false" :bodyStyle="{padding: '10px'}">
          <div class="order-content">
            <div class="table-head">
              <a-row>
                <a-col :span="10" class="head-font">订单</a-col>
                <a-col :span="14" class="head-font">
                  <a-row>
                    <a-col :span="6" class="head-font-last">金额</a-col>
                    <a-col :span="6" class="head-font-last">数量</a-col>
                    <a-col :span="6" class="head-font-last">状态</a-col>
                    <a-col :span="6" class="head-font-last">操作</a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
            <div class="order-list" v-for="(order, onderIndex) in orderList" :key="onderIndex">
              <div class="order-header-cus">
                <div class="pl-2">
                  <Icon custom="iconfont icon-shangdian" style="font-size: 18px;" class="mr-1"/>
                  <span class="mer-name cursor-pointer"
                        @click="()=>{$router.push({path:'/MerHome',query:{merId: order.merId}})}">{{
                      order.merName
                    }}</span>
                  <span class="order-time"> &nbsp; &nbsp; &nbsp; 订单号：{{ order.orderNo }}</span>
                </div>
                <div>
                  <span class="order-time mr-5">{{ order.createTime }}</span>
                </div>
              </div>

              <div class="order-body">
                <a-row>
                  <a-col :span="10" class="head-font">
                    <div class="goods-list">
                      <div v-for="(goods, goodsIndex) in order.orderInfoList" :key="goodsIndex">
                        <a-image :width="80" :height="80" :preview="false" @click="goodsDetail(goods.productId)"
                                 class="hover-color"
                                 :src="goods.image" alt=""
                                 :fallback="require('@/assets/index/logo.png')"/>
                        <div class="ml-4 position-relative">
                          <div class="hover-color pro-name" @click="goodsDetail(goods.productId)">{{
                              goods.productName
                            }}
                          </div>
                          <div class="mt_10 position-absolute bottom-0">
                            <span>{{ goods.sku }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-col>
                  <a-col :span="14" class="head-font-last">
                    <a-row>
                      <a-col :span="7" class="head-font-last price text-red">{{ order.payPrice }} USDT</a-col>
                      <a-col :span="5" class="head-font-last text-center">x {{ order.totalNum }}</a-col>
                      <a-col :span="6" class="head-font-last text-center">
                        <div :style="'color:' +getColor(order.status)">{{ orderStatusFilter(order.status) }}</div>
                        <div style="color: #3D3D3D;" class="mt-2">
                          <a-button @click="orderDetail(order)" type="link" size="small">订单详情</a-button>
                        </div>
                      </a-col>
                      <a-col :span="6" class="head-font-last text-center flex justify-center align-center">
                        <div class="d-flex flex-column">
                          <a-button class="rounded-xl mb-3" @click="goPay(order)" size="small" type="primary"
                                    v-if="order.status === 0">
                            去支付
                          </a-button>
                          <a-button class="rounded-xl mb-3" @click="received(order)" size="small" type="primary"
                                    v-if="order.status === 4">
                            确认收货
                          </a-button>
                          <a-button class="rounded-xl mb-3" v-if="order.status === 9 || order.status === 6"
                                    @click="delOrder(order)" size="small">
                            删除订单
                          </a-button>
                          <!--售后状态：-1:未申请 0:待审核 1:商家拒绝 2：退款中 3:已退款 4:用户退货 5:商家待收货 6:已撤销-->
                          <a-button class="rounded-xl mb-3"
                                    v-if="order.status !== 0 && order.status !== 6 && order.status !== 9 && (order.refundStatus === -1 || order.refundStatus === 6)"
                                    @click="applyAfterSale(order)" size="small">
                            申请售后
                          </a-button>
                          <a-button class="rounded-xl mb-3"
                                    v-if="order.status !== 0 && order.status !== 6 && order.status !== 9 && (order.refundStatus === 0 || order.refundStatus === 4)"
                                    @click="afterSales(order)" size="small">
                            撤销售后
                          </a-button>
                          <a-button class="rounded-xl mb-3 bg-green"
                                    v-if="order.status !== 0 && order.status !== 6 && order.status !== 9 && order.refundStatus === 4"
                                    size="small" @click="callBackProduct(order)">
                            退回商品
                          </a-button>
                          <a-button class="rounded-xl mb-3"
                                    disabled
                                    v-if="order.status !== 0 && order.status !== 6 && order.status !== 9 && order.refundStatus !== -1 && order.refundStatus !== 6 && order.refundStatus !== 0 && order.refundStatus !== 4"
                                    size="small">
                            {{
                              order.refundStatus === 1 ? '商家已拒绝' : order.refundStatus === 2 ? '退款中' : order.refundStatus === 3 ? '已退款' : order.refundStatus === 4 ? '用户退货' : order.refundStatus === 5 ? '商家待收货' : ''
                            }}
                          </a-button>
                          <a-button class="rounded-xl" danger ghost @click="handleCancelOrder(order)"
                                    v-if="order.status === 0" size="small">
                            取消订单
                          </a-button>
                        </div>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="page-size mt-3" v-if="orderList && orderList.length > 0">
            <a-pagination :page-size="params.limit" :total="total" show-less-items @change="changePageNum"
                          :show-size-changer="false"
                          @page-size-change="changePageSize"/>
          </div>
        </a-card>
      </div>

      <!-- 订单列表 -->
      <div v-if="activeKey === '-10'">
        <empty v-if="replyList.length === 0"/>
        <a-card v-else :bordered="false" :bodyStyle="{padding: '10px'}">
          <div class="order-content">
            <div class="order-list" v-for="(reply, onderIndex) in replyList" :key="onderIndex">
              <div class="order-header-cus">
                <div class="pl-2">
                  <Icon custom="iconfont icon-shangdian" style="font-size: 18px;" class="mr-1"/>
                  <span class="mer-name cursor-pointer"
                        @click="()=>{$router.push({path:'/MerHome',query:{merId: reply.merId}})}">{{
                      reply.merName
                    }}</span>
                  <span class="order-time"> &nbsp; &nbsp; &nbsp; 订单号：{{ reply.orderNo }}</span>
                </div>
                <div>
                  <span class="order-time mr-5">{{ reply.createTime }}</span>
                </div>
              </div>

              <div class="order-body">
                <a-row>
                  <a-col :span="10" class="head-font">
                    <div class="goods-list">
                      <a-image :width="80" :height="80" :preview="false" @click="goodsDetail(reply.productId)"
                               class="hover-color"
                               :src="reply.image" alt=""
                               :fallback="require('@/assets/index/logo.png')"/>
                      <div class="ml-4 position-relative">
                        <div class="hover-color pro-name" @click="goodsDetail(reply.productId)">{{
                            reply.productName
                          }}
                        </div>
                        <div class="mt_10 position-absolute bottom-0">
                          <span>{{ reply.sku }}</span>
                        </div>
                      </div>
                    </div>
                  </a-col>
                  <a-col :span="14" class="head-font-last">
                    <a-row>
                      <a-col :span="7" class="head-font-last price text-red">{{ reply.price }} USDT</a-col>
                      <a-col :span="5" class="head-font-last text-center">x {{ reply.payNum }}</a-col>
                      <a-col :span="12" class="head-font-last text-center flex justify-end align-center">
                        <div class="d-flex flex-column justify-end pr-5">
                          <a-button class="rounded-xl mb-3" @click="evaluate(reply)" size="small" type="primary" danger>
                            评价
                          </a-button>
                        </div>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="page-size" v-if="replyList && replyList.length > 0">
            <a-pagination :page-size="params.limit" :total="total" show-less-items @change="changePlPageNum"
                          :show-size-changer="false"
                          @page-size-change="changePlPageSize"/>
          </div>
        </a-card>
      </div>

      <!-- 订单列表 -->
      <div v-if="activeKey === '-11'">
        <a-card :bordered="false" :bodyStyle="{padding: '10px'}">
          <div class="order-content">
            <div class="d-flex mt-3 mb-5">
              <!--              <div :class="actionKey === -1 ? 'refus-btn-bule' :''" class="mr-3 refus-btn cu-center cursor-pointer"-->
              <!--                   @click="changeWayRefuns(-1)">-->
              <!--                售后申请-->
              <!--              </div>-->
              <div :class="actionKey === 0 ? 'refus-btn-bule' :''" class="mr-3 refus-btn cu-center cursor-pointer"
                   @click="changeWayRefuns(0)">
                申请中
              </div>
              <div :class="actionKey === 9? 'refus-btn-bule' :''" class="mr-3 refus-btn cu-center cursor-pointer"
                   @click="changeWayRefuns(9)">
                申请记录
              </div>
            </div>
            <div v-if="actionKey === -1">
              <empty v-if="orderRefusList.length === 0"/>
              <div v-else class="order-list" v-for="(order, oderIndex) in orderRefusList" :key="oderIndex">
                <div class="order-header-cus">
                  <div class="pl-2">
                    <Icon custom="iconfont icon-shangdian" style="font-size: 18px;" class="mr-1"/>
                    <span class="mer-name cursor-pointer"
                          @click="()=>{$router.push({path:'/MerHome',query:{merId: order.merId}})}">
                    {{ order.merName }}
                  </span>
                    <span class="order-time"> &nbsp; &nbsp; &nbsp; 订单号：{{ order.orderNo }}</span>
                  </div>
                  <div>
                    <span class="order-time mr-5">{{ order.createTime }}</span>
                  </div>
                </div>

                <div class="order-body">
                  <a-row>
                    <a-col :span="10" class="head-font">
                      <div class="goods-list">
                        <a-image :width="80" :height="80" :preview="false" @click="goodsDetail(order.productId)"
                                 class="hover-color"
                                 :src="order.image" alt=""
                                 :fallback="require('@/assets/index/logo.png')"/>
                        <div class="ml-4 position-relative">
                          <div class="hover-color pro-name" @click="goodsDetail(order.productId)">{{
                              order.productName
                            }}
                          </div>
                          <div class="mt_10 position-absolute bottom-0">
                            <span>{{ order.sku }}</span>
                          </div>
                        </div>
                      </div>
                    </a-col>
                    <a-col :span="14" class="head-font-last">
                      <a-row>
                        <a-col :span="7" class="head-font-last price text-red">{{ order.price }} USDT</a-col>
                        <a-col :span="5" class="head-font-last text-center">x {{ order.payNum }}</a-col>
                        <a-col :span="12" class="head-font-last text-center flex justify-end align-center">
                          <div class="d-flex flex-column justify-end pr-5">
                            <a-button class="rounded-xl mb-3" @click="applyAfterSale(order)" size="small"
                                      type="primary">
                              申请售后
                            </a-button>
                          </div>
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </div>
            <!-- 退款订单号 -->
            <div v-else>
              <empty v-if="orderRefusList.length === 0"/>
              <div v-else class="order-list" v-for="(order, oderIndex) in orderRefusList" :key="oderIndex">
                <div class="order-header-cus">
                  <div class="pl-2">
                    <span class="order-time">退款订单号：{{ order.refundOrderNo }}</span>
                  </div>
                  <div class="mr-2">
                    <div class="text-gray cu-center" v-if="order.afterSalesType === 1">
                      <Icon style="color: #2b85e4" custom="iconfont icon-a-yituikuantuikuanzhong"/>
                      <span class="ml-1">仅退款</span>
                    </div>
                    <div class="text-gray cu-center" v-else>
                      <Icon class="text-green" custom="iconfont icon-jintuikuan"/>
                      <span class="ml-1">退货退款</span>
                    </div>
                  </div>
                </div>

                <div class="order-body">
                  <a-row>
                    <a-col :span="10" class="head-font">
                      <div class="goods-list">
                        <a-image :width="80" :height="80" :preview="false" class="hover-color"
                                 :src="order.image" alt=""
                                 :fallback="require('@/assets/index/logo.png')"/>
                        <div class="ml-4 position-relative">
                          <div class="hover-color pro-name">{{
                              order.productName
                            }}
                          </div>
                          <div class="mt_10 position-absolute bottom-0">
                            <span>{{ order.sku }}</span>
                          </div>
                        </div>
                      </div>
                    </a-col>
                    <a-col :span="14" class="head-font-last">
                      <a-row>
                        <a-col :span="7" class="head-font-last price text-red">退款金额: {{ order.refundPrice }} USDT
                        </a-col>
                        <a-col :span="5" class="head-font-last text-center">x {{ order.applyRefundNum }}</a-col>
                        <a-col :span="6" :style="'color:'+getRefundColor(order.refundStatus)"
                               class="head-font-last text-center">{{ refundStatusFilter(order.refundStatus) }}
                        </a-col>
                        <a-col :span="6" class="head-font-last text-center flex justify-end align-center">
                          <div class="d-flex flex-column justify-end pr-5" v-if="actionKey === 0 || (actionKey === 9 && order.refundStatus === 0)">
                            <a-button class="rounded-xl mb-3" @click="afterSales(order)" size="small">
                              撤销售后
                            </a-button>
                            <a-button v-if="order.refundStatus === 4 && order.afterSalesType === 2" class="rounded-xl mb-3 bg-green" @click="sendBack(order)" size="small"
                                      type="primary">
                              退回商品
                            </a-button>
                          </div>
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </div>

                <a-collapse v-model:activeKey="activeInfoKey" expand-icon-position="end" :bordered="false"
                            @change="orderRefundInfo($event, order)" accordion>
                  <a-collapse-panel :key="order.refundOrderNo" header="查看详情">
                    <div class="d-flex mb-3" v-if="refundInfo">
                      <div class="ml-3 mt-3 text-gray text-left">
                        <div>订单单号: {{ refundInfo.orderNo }}</div>
                        <div class="mt-1">退款方式: 原路退回</div>
                        <div class="mt-1">退款原因: {{ refundInfo.refundReasonWap }}</div>
                        <div class="mt-1">退款方式: 原路退回</div>
                        <div class="mt-1">申请时间: {{ refundInfo.createTime }}</div>
                      </div>
                      <div class="ml-6 mt-3 text-gray text-left">
                        <div>售后类型: {{ refundInfo.afterSalesType === 1 ? '退货退款' : '仅退款' }}</div>
                        <div class="mt-1">退回方式:
                          {{
                            refundInfo.returnGoodsType === 1 ? '快递退回' : refundInfo.returnGoodsType === 2 ? '到店退货' : '不退货'
                          }}
                        </div>
                        <div class="mt-1">备注说明:
                          {{ refundInfo.refundReasonWapExplain ? refundInfo.refundReasonWapExplain : '无' }}
                        </div>
                        <div class="mt-1 cursor-pointer"
                             @click="$Copy({text: refundInfo.receiver+','+refundInfo.receiverPhone+','+refundInfo.receiverAddressDetail})"
                             v-if="refundInfo.returnGoodsType ===1 && refundInfo.refundStatus>0 &&refundInfo.receiver&&refundInfo.receiverPhone&&refundInfo.receiverAddressDetail">
                          <div class="cu-center">
                            退单地址:
                            <span class="text-bold text-body">
                          {{
                                '商家: ' + refundInfo.receiver + '  商家电话: ' + refundInfo.receiverPhone + '  商家地址' + refundInfo.receiverAddressDetail
                              }}
                            </span>
                            <Icon class="mt-1 cursor-pointer" type="md-copy" :size="18"/>
                          </div>
                        </div>

                        <div class="mt-1 cursor-pointer"
                             @click="$Copy({text: refundInfo.trackingNumber})"
                             v-if="refundInfo.returnGoodsType ===1 && refundInfo.refundStatus>0 && refundInfo.expressName && refundInfo.trackingNumber">
                          退货物流:
                          {{
                            refundInfo.trackingNumber
                          }}
                        </div>
                      </div>
                    </div>
                    <div lass="mb-3">
                      <div class="mb-3 mt-3 text-gray text-left" v-if="refundImages && refundImages.length >0">
                        <a-image-preview-group>
                          <a-image :width="100" :height="100" :src="item" v-for="(item, index) in refundImages"
                                   :key="index"/>
                        </a-image-preview-group>
                      </div>
                    </div>
                  </a-collapse-panel>
                </a-collapse>

              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="page-size" v-if="orderRefusList && orderRefusList.length > 0">
            <a-pagination :page-size="params.limit" :total="total" show-less-items @change="changeRePageNum"
                          :show-size-changer="false"
                          @page-size-change="changeRePageSize"/>
          </div>
        </a-card>
      </div>
    </a-spin>

    <Evaluate :visible="evaluateView" @change="evaluateChange"/>
    <SendBack :visible="sendBackView" @change="sendBackChange"/>
    <AfterSales :visible="afterSalesView" @change="afterSalesChange"/>
  </div>
</template>

<script>
import {
  getOrderList,
  orderCancel,
  orderComment,
  orderDel,
  orderNum,
  orderRefundInfo,
  orderRefundList,
  orderRefundVerify,
  orderReplyList,
  orderTake,
  refundOrderRevokeApi,
  returningGoodsApi,
} from '@/api/order'

import { getMerAddressApi } from '@/api/merchant'

import { orderStatusFilter } from '@/filter/commFilter'
import { Modal } from 'ant-design-vue'
import { Debounce } from '@/utils/validate'

import Evaluate from '@/components/evaluate/index.vue'
import SendBack from '@/components/sendBack/index.vue'
import AfterSales from '@/components/afterSales/index.vue'

export default {
  name: 'MyOrder',
  components: {
    Evaluate,
    SendBack,
    AfterSales
  },
  props: {
    homePage: { // 判断是否个人中心首页展示内容
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      afterSalesView: false,
      sendBackView: false,
      activeInfoKey: '',
      actionKey: 0,
      activeKey: '-1',
      orderList: [], // 订单列表
      params: { // 请求参数
        page: 1,
        limit: 10,
        keywords: '',
        status: '-1'
      },
      cancelParams: { // 取消售后参数
        orderSn: '',
        reason: ''
      },
      changeWay: ['全部订单', '待付款', '待收货', '已完成'], // 订单状态
      total: 0, // 数据总数
      spinShow: false, // 加载状态
      afterSaleModal: false, // 选择售后商品模态框
      afterSaleColumns: [ // 售后商品表头
        {
          title: '商品名称',
          key: 'name'
        },
        {
          title: '价格',
          key: 'goodsPrice'
        }
      ],
      afterSaleArr: [], // 售后商品列表
      cancelAvail: false, // 取消订单modal控制
      cancelReason: [], // 取消订单原因
      replyList: [],
      evaluateView: false,
      evaluateInfo: {},
      evaluateCount: 0,
      orderRefusList: [],
      refundInfo: {},
      refundImages: [],
      senBackInfo: {},
      applyAfterInfo: {}
    }
  },
  mounted() {
    this.params.status = this.$route.query.status
    if (!this.params.status) {
      this.params.status = -1
    } else {
      this.activeKey = this.params.status
    }
    if (this.params.status !== '-10' && this.params.status !== '-11') {
      this.getList()
    }
    if (this.params.status === '-10') {
      this.getReplyList()
    }
    if (this.params.status === '-11') {
      this.getOrderRefundList()
    }
    this.getOrderData()
  },
  methods: {
    afterSalesChange(e) {
      if (e) {
        this.subRefund(e)
      }
      this.afterSalesView = false
    },
    /**
     * 申请退货
     */
    subRefund: Debounce(function (e) {
      let that = this
      orderRefundVerify({
        num: that.applyAfterInfo.totalNum,
        text: e.index || '',
        explain: e.evaluate,
        reasonImage: e.pics && e.pics.length > 0 ? e.pics.join(',') : '',
        orderDetailId: that.applyAfterInfo.orderInfoList[0].id,
        orderNo: that.applyAfterInfo.orderNo,
        afterSalesType: e.afterSalesType,
        returnGoodsType: e.afterSalesType === 1 ? 0 : 1
      })
          .then(res => {
            if (res.code === 200) {
              this.getList()
              this.getOrderRefundList()
            } else {
              this.$Message.error(res.message)
            }
          })
    }),
    sendBackChange(e) {
      if (e) {
        this.sendBackForm(e)
      }
      this.sendBackView = false
    },
    //提交
    sendBackForm: Debounce(function (value) {
      let data
      if (this.senBackInfo.returnGoodsType === 1) {
        data = {
          refundOrderNo: this.senBackInfo.refundOrderNo,
          telephone: value.phone,
          trackingNumber: value.tracking,
          expressName: value.logistics
        }
      } else {
        data = {
          refundOrderNo: this.refundOrderNo,
          telephone: value.phone
        }
      }
      returningGoodsApi(data)
          .then(res => {
            if (res.code === 200) {
              this.getList()
              this.getOrderRefundList()
            } else {
              this.$Message.error(res.message)
            }
          })
    }),
    callBackProduct(order){
      this.senBackInfo.returnGoodsType = 1
      this.senBackInfo.refundOrderNo = order.refundOrder
      this.sendBackView = true
    },
    sendBack(order) {
      this.senBackInfo = order
      this.sendBackView = true
    },
    afterSales(item) {
      this.onRevokeRefund(item)
    },
    onRevokeRefund(item) {
      Modal.confirm({
        title: '确认撤销?',
        onOk: () => {
          refundOrderRevokeApi(item.refundOrderNo ? item.refundOrderNo : item.refundOrder)
              .then(res => {
                this.getList()
                this.getOrderRefundList()
              })
        }
      })
    },
    orderRefundInfo(e, item) {
      let that = this
      this.refundImages = []
      this.refundInfo = {}
      orderRefundInfo(item.refundOrderNo)
          .then(res => {
            let data = res.data
            this.refundInfo = data
            if (this.refundInfo.refundReasonWapImg) {
              this.refundImages = this.refundInfo.refundReasonWapImg.split(',')
            }
            if (data.returnGoodsType === 2) that.getMerAddress(data)
          })
    },
    //店铺地址
    getMerAddress(data) {
      getMerAddressApi(data.merId)
          .then(res => {
            data.merAddress = res.data
          })
    },
    changeWayRefuns(e) {
      this.actionKey = e
      this.params.page = 1
      this.getOrderRefundList()
    },
    getOrderRefundList() {
      let that = this
      that.loading = true
      orderRefundList({
        page: that.params.page,
        limit: that.params.limit,
        keywords: that.params.keywords,
        type: that.actionKey
      })
          .then(res => {
            let list = res.data.list || []
            that.total = res.data.total
            that.orderRefusList = list
            that.loading = false
          })
    },
    getOrderData() {
      orderNum()
          .then(res => {
            this.evaluateCount = res.data.awaitReplyCount
            if (!this.evaluateCount || this.evaluateCount <= 0) {
              this.evaluateCount = ''
            }
          })
    },
    evaluate(item) {
      this.evaluateInfo = item
      this.evaluateView = true
    },
    evaluateChange(e) {
      if (!e) {
        return this.evaluateView = false
      }
      this.evaluateView = false
      this.formSubmit(e)
    },
    formSubmit(e) {
      let value = {
        comment: e.evaluate,
        star: e.rateProduct,
        pics: e.pics,
        orderDetailId: this.evaluateInfo.id,
        orderNo: this.evaluateInfo.orderNo,
      }
      orderComment(value)
          .then(res => {
            if (res.code === 200) {
              this.$Message.error('感谢您的评价,谢谢!~~')
              this.params.page = 1
              this.getReplyList()
              this.getOrderData()
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    getReplyList: function () {
      let that = this
      that.loading = true
      orderReplyList({
        page: that.params.page,
        limit: that.params.limit
      })
          .then(res => {
            that.replyList = res.data.list || []
            that.total = res.data.total
            that.loading = false
            that.orderList = []
          })
    },
    orderStatusFilter,
    goodsDetail(goodsId) {
      this.$router.push({
        path: '/product',
        query: {
          id: goodsId,
        }
      })
    },
    // 切换订单状态
    changeOrderStatus(index) {
      this.params.status = index
      if (this.params.status !== '-10' && this.params.status !== '-11') {
        this.params.page = 1
        this.getList()
      }
      if (this.params.status === '-10') {
        this.params.page = 1
        this.getReplyList()
      }
      if (this.params.status === '-11') {
        this.params.page = 1
        this.getOrderRefundList()
      }
    },
    orderDetail(item) {
      // 跳转订单详情
      this.$router.push({
        name: 'OrderDetails',
        query: {
          orderNo: item.orderNo,
        }
      })
    },
    received: Debounce(function (item) {
      let that = this
      Modal.confirm({
        title: '确认收货',
        content: '为保障权益，请收到货确认无误后，再确认收货',
        onOk: () => {
          orderTake(item.orderNo)
              .then(res => {
                this.getList()
              })
        }
      })
    }),
    goPay(item) { // 去支付
      this.$router.push({
        path: '/Payment',
        query: {
          orderNo: item.orderNo,
          payPrice: item.payPrice,
        }
      })
    },
    applyAfterSale(order) {
      this.applyAfterInfo = order
      this.afterSalesView = true
    },
    delOrder(item) {
      Modal.confirm({
        title: '确认删除?',
        onOk: () => {
          orderDel(item.orderNo)
              .then(res => {
                this.getList()
              })
        },
      })
    },
    getList() { // 获取订单列表
      this.spinShow = true
      let params = JSON.parse(JSON.stringify(this.params))
      getOrderList(params)
          .then(res => {
            this.spinShow = false
            if (res.code === 200) {
              this.orderList = res.data.list
              this.total = res.data.total
            }
          })
    },
    changeRePageNum(val) { // 修改页码
      this.params.page = val
      this.getOrderRefundList()
    },
    changeRePageSize(val) { // 修改页数
      this.params.page = 1
      this.params.limit = val
      this.getOrderRefundList()

    },
    changePlPageNum(val) { // 修改页码
      this.params.page = val
      this.getReplyList()
    },
    changePlPageSize(val) { // 修改页数
      this.params.page = 1
      this.params.limit = val
      this.getReplyList()
    },
    changePageNum(val) { // 修改页码
      this.params.page = val
      this.getList()
    },
    changePageSize(val) { // 修改页数
      this.params.page = 1
      this.params.limit = val
      this.getList()
    },
    handleCancelOrder(item) {
      let that = this
      Modal.confirm({
        title: '确认取消',
        onOk: () => {
          orderCancel(item.orderNo)
              .then(res => {
                that.getList()
              })
        }
      })
    },
    refundStatusFilter(status) {
      const statusMap = {
        0: '商家审核中',
        1: '商家已拒绝',
        2: '商家已同意，退款中',
        3: '退款成功',
        4: '商家已同意',
        5: '等待商家收货并退款',
        6: '撤销申请',
      }
      return statusMap[status]
    },
    getRefundColor(status) {
      const statusMap = {
        0: '#269FFA',
        1: 'gray',
        2: 'green',
        3: 'green',
        4: '#FFAB1B',
        5: '#FFAB1B',
        6: 'gray'
      }
      return statusMap[status]
    },
    getColor(status) {
      const statusMap = {
        0: '#269FFA',
        1: '#269FFA',
        4: '#FFAB1B',
        5: '#FFAB1B',
        6: 'green',
        9: '#999999'
      }
      return statusMap[status]
    },
  }
}
</script>

<style scoped lang="scss">
.refus-btn-bule {
  border: 1px solid #2b85e4 !important;
  color: #2b85e4 !important;
}

.refus-btn {
  width: 100px;
  height: 32px;
  border-radius: 50px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #D9D9D9;

  font-size: 13px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0em;

  color: #999999;
}

.pay-unuse {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 66px !important;
  height: 22px !important;
  border-radius: 100px;
  border: 1px solid gray;

  font-size: 13px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;
  color: gray;
}

.price {
  font-weight: 500;
  font-size: 14px;
  color: #3D3D3D;
  text-align: center;
}

.pro-name {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;
  width: 258px;
  color: #3D3D3D;
}

.order-time {
  font-size: 13px;
  font-weight: 350;
  line-height: 26px;
  letter-spacing: 0em;
  color: #8C8C8C;
}

.mer-name {
  font-size: 13px;
  font-weight: bold;
  line-height: 26px;
  letter-spacing: 0em;
  color: #3D3D3D;
}

.order-header-cus {
  height: 32px;
  background: #F5F5F5;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.wrapper {
  margin-bottom: 40px;
}

.table-head {
  height: 32px;
  opacity: 1;
  background: #F5F5F5;
  padding: 5px;
  margin-bottom: 10px;

  .head-font {
    padding-left: 5px;
    font-size: 14px;
    font-weight: 350;
    line-height: 25px;
    letter-spacing: 0px;
    color: #515151;
  }

  .head-font-last {
    text-align: center;
    font-size: 14px;
    font-weight: 350;
    line-height: 25px;
    letter-spacing: 0px;
    color: #515151;
  }
}

.box {
  overflow: hidden;
  width: 100%;
  border-radius: 12px;
  opacity: 1;

  .top-center {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;

    :deep(.ant-badge .ant-badge-count) {
      top: -6px !important;
    }
  }
}

.page-size {
  text-align: right;
}

/** 订单列表 */
.order-list {
  border: 1px solid #E4E4E4;
  border-radius: 3px;
  margin-bottom: 10px;

  &:hover {
    .del-btn {
      visibility: visible;
    }
  }

  .del-btn {
    visibility: hidden;
  }

  .order-header {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    border: 1px solid #E4E4E4;

    > div:nth-child(1) > div:nth-child(2) {
      font-size: 12px;
      color: #999;
      margin-top: 3px;
    }
  }

  .order-body {
    //display: flex;
    //justify-content: space-between;
    color: #999;
    padding: 10px;

    .goods-list > div {
      //width: 500px;
      display: flex;
      margin-bottom: 10px;


      img {
        width: 80px;
        height: 80px;
        margin-right: 10px;
      }

      > div {
        flex: 1;
      }
    }

    > div:nth-child(2) {
      width: 150px;
      text-align: center;

      span {
        color: #438cde;
        cursor: pointer;

        &:hover {
          color: #F31947;
        }
      }

      .ivu-icon {
        color: #ff8f23;
        cursor: pointer;

        &:hover {
          color: #F31947;
        }
      }
    }

    > div:nth-child(3) {
      width: 100px;

      .ivu-btn {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
