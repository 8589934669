<template>
  <a-config-provider :locale="locale" :theme="{ algorithm: algorithmTheme}">
    <router-view v-if="showPc"/>
  </a-config-provider>
</template>

<script>
import { useDark, useToggle } from '@vueuse/core'

import zhCN from 'ant-design-vue/es/locale/zh_CN'
import enUS from 'ant-design-vue/es/locale/en_US'

import { theme } from 'ant-design-vue'

export default {
  name: 'App',
  created() {
    const i18n = localStorage.getItem('language')
    if (i18n) {
      this.$i18n.locale = i18n
    } else {
      this.$i18n.locale = 'zh'
    }
    const status = localStorage.getItem('dark_css')
    if (status === 'true') {
      this.change(true)
    } else {
      this.change(false)
    }
  },
  watch: {
    '$store.getters.language': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (newVal === 'zh') {
            this.locale = zhCN
          } else {
            this.locale = enUS
          }
        })
      }
    },
    '$store.getters.dark': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (newVal === true) {
            this.algorithmTheme = theme.darkAlgorithm
          } else {
            this.algorithmTheme = theme.defaultAlgorithm
          }
        })
      }
    }
  },
  data() {
    return {
      algorithmTheme: '',
      locale: zhCN,
      options: {
        top: 420,
      },
      showPc: false
    }
  },
  mounted() {
    this.$Message.config(this.options)
    if (this._isMobile()) {
      let pathName = window.location.pathname
      let h5Url = 'https://h5.51danbao.com'
      if (pathName === '/MerHome') {
        h5Url = h5Url + '/pages/merchant/home/index?id=' + this.getRequest()
      }
      if (pathName === '/Product') {
        h5Url = h5Url + '/pages/goods/goods_details/index?id=' + this.getRequest()
      }
      window.location.href = h5Url
    } else {
      this.showPc = true
    }
  },
  beforeDestroy() {
    this.$Message.destroy()
  },
  methods: {
    _isMobile() {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    },
    getRequest() {
      var url = location.search
      if (url.indexOf('?') !== -1) {
        var str = url.substr(1)
        var strs = str.split('=')
        return strs[1]
      }
    },
    change(status) {
      const html = document.documentElement
      const body = document.getElementsByTagName('body')[0]
      const isDark = useDark({
        selector: 'html',
        attribute: 'data-doc-theme',
        valueDark: 'dark',
        valueLight: 'light',
        onChanged(dark) {
          if (status) {
            const links = document.getElementsByTagName('link')
            for (let i = 0; i < links.length; i++) {
              if (links[i].href.indexOf('antd.light.css') > -1) {
                links[i].href = ''
              }
            }

            const link = document.createElement('link')
            link.rel = 'stylesheet'
            link.type = 'text/css'
            body.appendChild(link)
            document.body.setAttribute('data-theme', 'dark')
            html.setAttribute('data-doc-theme', 'dark')
            html.style.colorScheme = 'dark'
            link.href = '/style/antd.dark.css'
          } else {
            const links = document.getElementsByTagName('link')
            for (let i = 0; i < links.length; i++) {
              if (links[i].href.indexOf('antd.dark.css') > -1) {
                links[i].href = ''
              }
            }
            document.body.removeAttribute('data-theme')
            html.removeAttribute('data-doc-theme')
            html.style.colorScheme = ''

            const link = document.createElement('link')
            link.rel = 'stylesheet'
            link.type = 'text/css'
            body.appendChild(link)
            link.href = '/style/antd.light.css'
          }
        },
      })
      // 缓存增加
      localStorage.setItem('dark_css', status)
      useToggle(isDark)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
