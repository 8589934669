<template>
  <div>
    <a-card>
      <a-list>
        <a-list-item>
          <a-list-item-meta>
            <template #avatar>
              <a-avatar :size="100" :src="merchantInfo.avatar" class="cursor-pointer" @click="() => {$router.push({ path: '/MerInfo',query:{
                merId:merchantInfo.merId
              } })}"/>
            </template>
            <template #title>
              <div class="merName cursor-pointer" @click="() => {$router.push({ path: '/MerInfo',query:{
                merId:merchantInfo.merId
              } })}">
                {{ merchantInfo.name }} <span v-if="store.intro" class="ml-3 text-gray text-sm">{{ store.intro }}</span>
              </div>
            </template>
            <template #description>
              <div class="danbao-name cursor-pointer" style="padding-right: -20px"
                   @click="() => {$router.push({ path: '/MerInfo',query:{ merId:merchantInfo.merId } })}">
                <span class="star">担保金 :</span>
                <span class="gold-font">  {{ merchantInfo.earnestMoney }} </span>
                <span class="usdt">USDT</span>
              </div>
            </template>
          </a-list-item-meta>
          <template #extra>
            <div class="d-flex" style="margin-left:10px">
              <div v-if="showJoin" class="btn-1 mr-2 cursor-pointer"
                   @click="() => {$router.push({ path: '/MerHome',query:{ merId:merchantInfo.merId } })}">
                进店
              </div>
              <div class="btn-1 cursor-pointer" @click="followToggle(isCollect)">
                {{ isCollect ? '已关注' : '+关注店铺' }}
              </div>
              <a-popover placement="bottomLeft" trigger="click" style="width: 440px;">
                <template #title>
                  <div style="border-bottom: 1px solid #e8e8e8;height: 26px;">将链接分享至Telegram</div>
                </template>
                <template #content>
                  <div class="mt-6">
                    <div class="text-gray tg-url-share">
                      {{ tagUrl }}
                      <div class="copy-btn" @click="$Copy({text: tagUrl})">复制</div>
                    </div>
                    <div class="telegram-btn" @click="toTelegram">
                      将链接分享至Telegram
                    </div>
                  </div>
                </template>
                <div class="btn-2 cursor-pointer">
                  <icon-share-internal/>
                  分享店铺
                </div>
              </a-popover>
              <div class="btn-3 cursor-pointer" @click="customerOpen">
                <Icon type="ios-headset-outline"/>
                联系客服
              </div>
            </div>
          </template>
        </a-list-item>
      </a-list>
    </a-card>
    <!-- 遮罩层 -->
    <div v-if="open" class="fullscreen-overlay">
      <div class="close cursor-pointer" @click="open = false">
        <Icon class="close" type="md-close" color="#FFFFFF" :size="25"/>
      </div>
      <div class="overlay-content">
        <a-card :bodyStyle="{padding: '10px', textAlign: 'center'}" class="cu-center">
          <div id="posterHtml" class="posterBox">
            <div class="qrcodeBoxBanner" :style="{backgroundImage: 'url(' + merchantInfo.avatar + ')' }"></div>
            <div class="text-lg text-bold mt-3">{{ merchantInfo.name }}</div>
            <div class="line3 margin-top-xs">{{ store.intro }}</div>
            <div class="line-ge margin-top-lg">
            </div>
            <div class="infoBox">
              <div class="text-bold text-left home-name">51担保网</div>
              <div class="text-black text-left mt-3 text-lg">www.51danbao.com</div>
              <div class="text-black mt-3">
                信任始于保障 安全源于担保
              </div>
            </div>
            <div class="qrcodeBox">
              <a-spin size="large" :spinning="loading">
              </a-spin>
              <div id="qrcodeImg"></div>
            </div>
          </div>
          <div class="cu-center mt-5 btn-down">
            <a-button type="link" class="myButton" @click="createPoster()">下载分享海报</a-button>
          </div>
        </a-card>
      </div>
    </div>
    <Chat :preview-visible="previewVisible" :db-mer-id="$route.query.merId" @close="close()"/>
  </div>
</template>
<script>
import { company } from '@/config'
import QRCode from 'qrcodejs2-fix'
import html2canvas from 'html2canvas'

export default {
  props: {
    merchantInfo: {
      type: Object,
      default: {}
    },
    store: {
      type: Object,
      default: {}
    },
    isCollect: {
      type: Boolean,
      default: false
    },
    showJoin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      open: false,
      tagUrl: company.href + '/MerHome?merId=',
    }
  },
  created() {
    this.tagUrl = this.tagUrl + this.$route.query.merId
  },
  methods: {
    toTelegram() {
      window.open(`https://t.me/share/url?url=${this.tagUrl}`, '_blank')
    },
    customerOpen() {
      if (!this.$store.getters.token) {
        this.$Message.error('请先登录')
        this.$router.push({ path: '/Login' })
      } else {
        let routeUrl = this.$router.resolve({
          path: '/ChatIm',
          query: {
            dbMerId: this.$route.query.merId
          }
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    shareOpen() {
      this.open = true
      this.loading = true
      setTimeout(() => {
        this.make()
        this.loading = false
      }, 1000)
    },
    // 生成二维码；
    make() {
      let href = this.tagUrl
      const elementId = 'qrcodeImg'
      let qrcodeImgEl = document.getElementById(elementId)
      qrcodeImgEl.innerHTML = ''
      let qrcode = new QRCode(qrcodeImgEl, {
        width: 120,
        height: 120,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
      qrcode.makeCode(href)
    },
    createPoster() {
      let that = this
      let posterDOM = document.getElementById('posterHtml')
      html2canvas(posterDOM, {
        width: posterDOM.offsetWidth,
        height: posterDOM.offsetHeight,
        //按比例增加分辨率
        scale: window.devicePixelRatio, // 设备像素比
        useCORS: true,//（图片跨域相关）
        allowTaint: true,//允许跨域（图片跨域相关）
        logging: false,
        letterRendering: true,
      })
          .then(function (canvas) {
            that.posterImgURL = canvas.toDataURL('image/png')
            let a = document.createElement('a')
            a.download = '51担保'
            a.href = that.posterImgURL
            a.dispatchEvent(new MouseEvent('click'))
          })
    },
    followToggle(e) {
      this.$emit('followToggle', e)
    }
  }
}
</script>
<style scoped lang="scss">
.home-name {
  font-size: 18px;
  font-weight: 900;
}

.telegram-btn {
  margin-top: 15px;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  height: 36px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #269FFA;
  color: #FFFFFF;
}

.tg-url-share {
  border: 1px solid #e8e8e8;
  height: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding: 10px;

  .copy-btn {
    cursor: pointer;
    margin-left: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid #e8e8e8;
    height: 25px;
    border-radius: 10px;
    color: #666666;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.line-ge {
  border-bottom: 1px solid #000000;
}

/*海报*/
.posterBox {
  position: relative;
  width: 407px;
  height: 700px;
  background-repeat: no-repeat;
  background-size: cover;

  .qrcodeBoxBanner {
    width: 407px;
    height: 407px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .qrcodeBox {
    position: absolute;
    right: 20px;
    bottom: 15px;
    z-index: 9999;
  }

  .infoBox {
    position: absolute;
    left: 10px;
    bottom: 30px;
    z-index: 9999;

    .infoBox-font {
      width: 197px;
      text-align: left;
    }
  }
}


.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  z-index: 9000; /* 确保遮罩层在最上层 */
}

.overlay-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  /* 其他样式 */
}

.star {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #999999;
}

.btn-1 {
  width: 100px;
  height: 32px;
  border-radius: 50px;
  background: #269FFA;
  color: #FFFFFF;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
}

.btn-3 {
  margin-left: 10px;
  width: 100px;
  height: 32px;
  border-radius: 50px;
  border: 1px solid #5EAC65;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;

  color: #5EAC65;
}

.btn-2 {
  width: 100px;
  height: 32px;
  border-radius: 50px;
  background: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #D9D9D9;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0em;
  color: #999999;
}

.merName {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
}

.danbao-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #999999;

  .gold-font {
    margin-left: 5px;
    font-weight: 700;
    font-size: 13px;
    color: #FF6F4A;
  }

  .usdt {
    margin-left: 5px;
    font-weight: 400;
    font-size: 13px;
    color: #FF6F4A;
  }
}
</style>
