<template>
  <div class="wrapper">
    <a-row :gutter="[16, 24]">
      <a-col :span="12">
        <a-card :bordered="false" :body-style="{height: '144px', width:'630px', padding: '0'}">
          <div class="card-top">
            <div class="item-1">
              <a-image :src="require('@/assets/share/one.png')" :width="100" :height="100" :preview="false"></a-image>
            </div>
            <div class="item-2">
              <div class="item-title">
                我的可用余额
                <span class="cursor-pointer ml-1 text-blue" style="font-size: 12px;"
                      @click="tabsIndexClick(0)">详情记录</span>
              </div>
              <div class="item-number">{{ statistics.nowMoney ? statistics.nowMoney : '0.00' }}</div>
              <div class="item-unit">(USDT)</div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card :bordered="false" :body-style="{height: '144px', width:'630px', padding: '0'}">
          <div class="card-top">
            <div class="item-2-s">
              <div class="item-title-s flex align-items-center">
                <div>
                  余额充值:
                  <span class="item-number-s ml-1">{{ statistics.recharge ? statistics.recharge : '0.00' }} </span>
                  <span class="item-unit-s  ml-1">(USDT)</span>
                </div>
                <div class="ml-1 btn-all" @click="tabsIndexClick(1)">充值</div>
              </div>

              <div class="item-title-s mt-1">
                余额消费:
                <span class="item-number-s ml-1">{{ statistics.monetary ? statistics.monetary : '0.00' }} </span>
                <span class="item-unit-s ml-1">(USDT)</span>
              </div>

              <div class="item-title-s mt-1 flex align-items-center">
                <div>
                  累计已提:
                  <span class="item-number-s ml-1">{{ statistics.withdraw ? statistics.withdraw : '0.00' }} </span>
                  <span class="item-unit-s ml-1">(USDT)</span>
                </div>
                <div class="ml-1 btn-all-1" @click="tabsIndexClick(2)">提现</div>
              </div>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>

    <a-spin size="large" :spinning="loadingSpin">
      <div v-if="tabsIndex === 0">
        <div class="margin-top-sm">
          <a-card :bordered="false" :body-style="{ padding: '3px'}">
            <a-menu v-model:selectedKeys="current" mode="horizontal" :items="items" @click="selectMenu"/>
            <a-list item-layout="horizontal" :data-source="userBillList" :loading="loading"
                    v-if="current[0] === 'all' || current[0] === 'expenditure' || current[0] === 'recharge' || current[0] === 'income' ">
              <template #renderItem="{ item }">
                <a-list-item>
                  <a-list-item-meta>
                    <template #description>
                      <div class="text-gray" style="font-size: 13px">说明: {{ item.remark }}</div>
                    </template>
                    <template #title>
                      <div class="cursor-pointer" style="width: 480px;color: #666666;"
                           @click="$Copy({text: item.linkId})">
                        订单号: {{ item.linkId }}
                      </div>
                      <div class="text-gray" style="font-size: 14px;">时间: {{ item.createTime }}</div>
                    </template>
                  </a-list-item-meta>
                  <template #actions>
                    <div class="flex mr-5">
                      <div class="text-grey cursor-pointer line1" style="width: 400px" v-if="!item.txHash">-</div>
                      <div v-else class="text-grey cursor-pointer line1" style="width: 400px"
                           @click="$Copy({text: item.txHash})">
                        {{ 'Hash ID: ' + item.txHash.substr(0, 32) + '....' }}
                      </div>
                    </div>
                  </template>
                  <template #extra>
                    <div class='text-red text-bold' v-if="item.type === 1">+ {{ item.amount }} USDT</div>
                    <div class='text-red text-bold' v-else>- {{ item.amount }} USDT</div>
                  </template>
                </a-list-item>
              </template>
            </a-list>

          </a-card>
        </div>
        <!-- 分页 -->
        <div class="page-size margin-top-xs" v-if="userBillList && userBillList.length > 0">
          <a-pagination :page-size="limit" :total="total" show-less-items @change="changePageNum"
                        :show-size-changer="false"
                        @page-size-change="changePageSize"/>
        </div>
      </div>
      <div class="margin-top-sm" v-if="tabsIndex === 1">
        <a-card :body-style="{padding: '10px'}">
          <template #title>我要充值</template>
          <div>
            <div class="flex">
              <a-qrcode
                  :size="220"
                  :icon-size="40"
                  error-level="H"
                  :value="userInfo.walletAddress"
                  :icon="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAA4VJREFUWEfFl3tIU1Ecx7/naqtsmZFhD6uJOiMqe+EfPS2iooweUNAfOkdQ9EcvKCLvykHeggoKKsKgtllQQdDrj8LIyrKHZZAR5J2yUeajDNOszOk9dSeOu7uz7bpWDe4f4/wen/P9/s52LsF//pD/3B+aAQw2a4IOP7NpDLeAUGQBSAJBkncDFM2A/NBScDFlYu6Bh1o3FhZgktWq8xg82wgh2wGarKUwBapAccaZL5wJFx8SwOjgswjFaUowI1wh1roMQiWyq9ZcdD9YflCAdIclh4DejKSxOkeSyMJgEEyAjBJ+B6U4Fo3mfTV6dBhft0F4r64ZAGC07V0Njrsazea9c4qqb50D5jdstn5X1vYDkAeux+B5ovb8/JKNyBqV0i+myiYXckvP+ucQ2MU8wRwUIN3O7yIER9SdogYgKyFRs9N80N7Xw6dA2gVrPNfT/YZ11KIJAMDdJQ2Y7jZbv8gQPoCJJQVLJUpus3TOSgqUf2vmIp8tstwnXpUFpFY2u9i2SdIa0Xzomh+A0W45CEL3ajVaqQrT7xCFKMFxZ56w0x/AwVcAmM3Kk3fb1vUDKfGJSNYPR4fnJxIH6X0KPGtyofHbFyQMjEN9Rytc7S0QW5sRVAHgsWgS5qgBagGkKgHG6hNgW2zG6df3Ud1Sj7q2T75lpQIyQJ5i4sfph2PayPE4NGctil8/YNlTJ5qENH+AEv4rKPRKgEfr9uCS+BwnGf6GAuircWXFFkwZMRYZJRb1cewQ84ShYQHkgGUTJmO9cRbc7Z/hamuBu733afd0Yv4Yo7dweYOIeN1gGBOSkJMyFdnJGYiL1eGy+Bz7n14PdJWAAeDgAyxQZ44eMgyG+ETIEq9MyfQ7Bfc+1KCz24O3rY14+fFduFlmWBBiCFnV/uQUgD2EBYW/fxas4dD71v8EgABHa0zCbv8ZOL9vHiSp/F8ASJQsrc0vKvUDkL+kO/gXBJipBSJyBUiFaCqaG/Bf4AWw85sIQfHfBCAcWVWTW3SDCdAfFSJRgALnnCZho3KDAReSNJslm+PoPS0q9DPmlmgSlqtzmFeyvwEhmgRmr6CX0tSL/DiuC1e1DmUINapFk5AZbD3ktXxMsTVOP9hzChT5/ZTbG87yXJMF6qB0W0E+4UghAIM2EFJBOBxWTntECiiTNL2aSfQOYmPuRvXVTNuOI48K+24YeWltmb8A/Ul6MLkJ3PgAAAAASUVORK5CYII='"
              />
              <div class="ml-3" style="position: relative;padding: 10px;">
                <div class="flex">
                  <div class="ml-3 cursor-pointer" style="font-size: 16px"
                       @click="$Copy({text: userInfo.walletAddress})">
                    充币地址: <span style="font-weight: 500"> {{ userInfo.walletAddress }}</span>
                  </div>

                  <div class="text-center cursor-pointer" @click="$Copy({text: userInfo.walletAddress})">
                    <Icon type="md-copy" :size="18"/>
                  </div>
                </div>
                <div class="ml-3 mt-1">
                  币种: Tether USD (USDT)
                </div>
                <div class="ml-3 mt-1">
                  充值网络: USDT-TRC20
                </div>

                <div style="position: absolute;bottom: 10px; left: 20px">
                  <div class="text-xs" style="font-weight: 500">交易信息</div>
                  <a-row :gutter="24">
                    <a-col :span="12">
                      最小充币数量:
                      <a-tooltip>
                        <template #title>若您的充币数量小于最小充币额，该笔充币将无法到账或退回</template>
                        <Icon class="text-gray" type="ios-alert" :size="16"/>
                      </a-tooltip>
                    </a-col>
                    <a-col :span="12">
                      <span class="margin-left">≥ 1.00 USDT</span>
                    </a-col>
                    <a-col :span="12">
                      充币到账:
                      <a-tooltip>
                        <template #title>预计到账时间 ≈ 2分钟</template>
                        <Icon class="text-gray" type="ios-alert" :size="18"/>
                      </a-tooltip>
                    </a-col>
                    <a-col :span="12">
                      <span class="margin-left">1次网络确认</span>
                    </a-col>
                  </a-row>
                  <div class="">请勿将非USDT-TRC20币种发送到该地址</div>
                </div>

              </div>
            </div>
          </div>
        </a-card>

        <a-card class="margin-top-sm" :bordered="false" :body-style="{padding: '10px'}">
          <template #title>
            <a-image :preview="false" :src="require('@/assets/join/qa.png')" :width="32" :height="32"></a-image>
            常见问题
          </template>
          <a-collapse v-model:activeKey="activeKey" expand-icon-position="end" :bordered="false" ghost accordion>
            <a-collapse-panel v-for="(item, index) in issueList" :key="index">
              <template #header>
                <div class="d-flex align-center">
                  <div class="iss-item-coll-title">
                    <a-image :src="require('@/assets/issue.png')" :width="28" :height="22"></a-image>
                    <div class="text-coll">{{ 'Q' + (index + 1) }}</div>
                  </div>
                  <div class="ml-1">
                    {{ item.issue }}
                  </div>
                </div>
              </template>
              <div class="html-font" v-html="item.answer"></div>
            </a-collapse-panel>
          </a-collapse>
        </a-card>
      </div>

      <div class="margin-top-sm" v-if="tabsIndex === 2">
        <a-card :body-style="{padding: '10px'}">
          <template #title>提现记录</template>
          <template #extra>
            <div class="flex-title-view cursor-pointer" @click="showView">
              提现申请
            </div>
          </template>
          <a-list item-layout="horizontal" :data-source="recordList" :loading="loading">
            <template #renderItem="{ item }">
              <a-list-item>
                <a-list-item-meta>
                  <template #description>
                    <div class="text-gray">
                      实际到账金额: {{
                        parseFloat(item.closingPrice - item.commission)
                            .toFixed(2)
                      }} USDT
                    </div>
                    <div class="text-gray">
                      网络费用: {{ item.commission }} USDT
                    </div>
                    <div v-if="item.auditStatus === 2" class="text-gray" style="font-size: 13px;color: red;">原因:
                      {{ item.refusalReason }}
                    </div>
                  </template>
                  <template #title>
                    <div class="cursor-pointer" style="width: 480px;color: #666666;"
                         @click="$Copy({text: item.closingNo})">
                      订单号: {{ item.closingNo }}
                    </div>
                    <div class="text-gray" style="font-size: 14px;">时间: {{ item.createTime }}</div>
                  </template>
                </a-list-item-meta>
                <view class='mr-10 line1 text-gray' v-if="item.auditStatus === 0">
                  <Icon type="ios-clock" :size="20"/>
                  待审核
                </view>
                <view class='mr-10 line1 text-green' v-if="item.auditStatus === 1">
                  <Icon type="ios-checkmark-circle" :size="20"/>
                  审核成功
                </view>
                <view class='mr-10 line1 text-red' v-if="item.auditStatus === 2">
                  <Icon type="ios-close-circle" :size="20"/>
                  审核失败
                </view>
                <template #extra>
                  <div class='text-red'>
                    <span class="text-bold text-xs">{{ item.closingPrice }} USDT</span>
                  </div>
                </template>
              </a-list-item>
            </template>
          </a-list>
        </a-card>
        <!-- 分页 -->
        <div class="page-size margin-top-xs" v-if="recordList && recordList.length > 0">
          <a-pagination :page-size="limit" :total="total" show-less-items @change="changePageNum"
                        :show-size-changer="false"
                        @page-size-change="changePageSize"/>
        </div>

        <a-card class="margin-top-sm" :bordered="false" :body-style="{padding: '10px'}">
          <template #title>
            <a-image :preview="false" :src="require('@/assets/join/qa.png')" :width="32" :height="32"></a-image>
            常见问题
          </template>
          <a-collapse v-model:activeKey="activeKey" expand-icon-position="end" :bordered="false" ghost accordion>
            <a-collapse-panel v-for="(item, index) in issueList" :key="index">
              <template #header>
                <div class="d-flex align-center">
                  <div class="iss-item-coll-title">
                    <a-image :src="require('@/assets/issue.png')" :width="28" :height="22"></a-image>
                    <div class="text-coll">{{ 'Q' + (index + 1) }}</div>
                  </div>
                  <div class="ml-1">
                    {{ item.issue }}
                  </div>
                </div>
              </template>
              <div class="html-font" v-html="item.answer"></div>
            </a-collapse-panel>
          </a-collapse>
        </a-card>
      </div>
    </a-spin>

    <a-modal v-model:open="xYVisible" title="申请提现"
             :width="620"
             :maskClosable="false"
             centered
             @cancel="xYVisible = false">
      <a-card :bordered="false" :body-style="{padding: '20px'}" type="inner">
        <a-form :model="formRegist" @finish="isSettingPayPwd" :label-col="{ style: { width: '90px' } }">
          <a-form-item name="coinaddress" :rules="[{ required: true, message: '请输入USDT地址' }]" class="mb-1 mt-2">
            <template #label>USDT地址</template>
            <template #help><span style="font-size: 12px;" class="text-gray">您所在TRON链网络的钱包地址</span>
            </template>
            <a-input class="input-cell" placeholder="请输入USDT地址" v-model:value="formRegist.coinaddress">
              <template #suffix>
                <Icon class="cursor-pointer" style="font-size: 18px;" custom="iconfont icon-iconfontdaifukuan"
                      @click="() => { $router.push({path: '/WalletList'})}"/>
              </template>
            </a-input>
          </a-form-item>

          <a-form-item name="network" :rules="[{ required: true, message: '请输入USDT地址' }]" class="mb-1 mt-2">
            <template #label>提币网络</template>
            <template #help><span style="font-size: 12px;" class="text-gray">网络是发送和接收数字货币的指定区块链路径.大多数币种会有多于一个的网络,所以请务必检查所选网络是否匹配,否则转账资产将会丢失.</span>
            </template>
            <a-input class="input-cell" disabled placeholder="请输入提币网络" v-model:value="formRegist.network"/>
          </a-form-item>

          <a-form-item name="closingPrice" :rules="[{ required: true, message: '请输入提币金额' }]" class="mb-1 mt-2">
            <template #label>提币金额</template>
            <template #help>
              <div style="font-size: 12px;" class="text-gray">网络费用: {{
                  parseFloat(settlementConfig.serviceCharge)
                      .toFixed(2)
                }}
              </div>
              <div class="text-red" style="font-size: 12px;">到账数量: {{
                  !formRegist.closingPrice ? '0.00' : parseFloat(formRegist.closingPrice - settlementConfig.serviceCharge)
                      .toFixed(2)
                }}
              </div>
            </template>
            <a-input class="input-cell"
                     type="number"
                     :placeholder="'输入提币金额, 最小'+parseFloat(settlementConfig.minPrice).toFixed(2)+'USDT'"
                     v-model:value="formRegist.closingPrice">
              <template #suffix>
                <a-button type="link" @click="maxClick">最大</a-button>
              </template>
            </a-input>
          </a-form-item>

          <a-form-item name="password" :rules="[{ required: true, message: '请输入支付密码' }]" class="mb-1 mt-2">
            <template #label>支付密码</template>
            <a-input-password :maxlength="6" class="input-cell" placeholder="请输入支付密码"
                              v-no-chinese
                              v-model:value="formRegist.password">
            </a-input-password>
          </a-form-item>

          <a-form-item>
            <div class="text-right text-gray">
              <div class="cursor-pointer" @click="() => { $router.push({path: '/AccountSafe'})}">{{ $t('pwd.pwd_001') }}?</div>
            </div>
          </a-form-item>

          <a-form-item class="mb-1 mt-2 text-right">
            <a-button html-type="submit" style="width: 126px" size="large" type="primary">确认</a-button>
          </a-form-item>
        </a-form>
      </a-card>
      <template #footer/>
    </a-modal>

    <Payment :showFlag="showFlag" :visible="payVisible" @complete="onComplete" @googleFinish="googleFinishs"/>
    <ShopTip :show-model="showModel" :tips="tipsOne" @close="showModel = false"></ShopTip>
  </div>
</template>

<script>
import {
  getBillList,
  getClosingRecordApi,
  getMyAccountApi,
  getUserInfo,
  isSet,
  withdrawCash,
  withdrawConfigApi, googleIsBuild,
} from '@/api/user.js'
import { queryNormalIssue } from '@/api/tg'
import { defaultWalletAddress } from '@/api/wallet'
import Payment from '@/components/payment/index.vue'
import ShopTip from '@/components/shopTip/index.vue'
import { Debounce } from '@/utils/validate'

export default {
  name: 'MyWallet',
  components: {
    ShopTip,
    Payment
  },
  data() {
    return {
      payVisible: false,
      showModel: false,
      tipsOne: '',
      formRegist: {
        coinaddress: '',
        network: 'USDT-TRC20',
        password: '',
        closingPrice: '',
      },
      xYVisible: false,
      activeKey: '',
      issueList: [],
      isClose: false,
      activity: {},
      hotScroll: false,
      statistics: {},
      isNoCommodity: false,
      current: ['all'],
      items: [
        {
          key: 'all',
          label: '全部',
        },
        {
          key: 'expenditure',
          label: '支出',
        },
        {
          key: 'recharge',
          label: '充值',
        },
        {
          key: 'income',
          label: '收入',
        }
      ],
      page: 1,
      limit: 12,
      total: 0,
      loading: true,
      userBillList: [],
      recordList: [],
      tabsIndex: 0,
      userInfo: {},
      settlementConfig: {},
      arrivalAmount: '',
      isGoogleBuild: false,
      showFlag: 0,
      googleCode: '',
      loadingSpin: false,
    }
  },
  mounted() {
    this.getUserTagInfo()
    this.getUserBillList()
    this.userBalance()
    // this.getIssueList(1)
  },
  methods: {
    payMentGoogle: Debounce(function () {
      this.loadingSpin = true
      let data = {
        type: 'TRC20',
        closingType: 'TRC20',
        actualprice: parseFloat(this.formRegist.closingPrice - this.settlementConfig.serviceCharge),
        bankName: 'TRON',
        coinaddress: this.formRegist.coinaddress,
        commission: this.settlementConfig.serviceCharge,
        paypassword: this.formRegist.password,
        closingPrice: this.formRegist.closingPrice,
        googleCode: this.googleCode
      }
      withdrawCash(data)
          .then(res => {
            if (res.code === 200) {
              this.$Message.success('申请成功')
              this.getList()
              this.loadingSpin = false
            } else {
              this.loadingSpin = false
              this.$Message.error(res.message)
            }
          })
    }),
    googleFinishs(e) {
      console.log(e)
      // this.payVisible = false
      // this.googleCode = e
      // this.payMentGoogle()
    },
    onComplete(e) {
      console.log(e)
      this.payVisible = false
      this.googleCode = e
      this.payMentGoogle()
    },
    isSettingPayPwd() {
      const minPrice = parseFloat(this.formRegist.closingPrice - this.settlementConfig.serviceCharge)
      if (minPrice <= 0) {
        return this.$Message.error('输入金额过小')
      }
      const maxPrice = parseFloat(this.formRegist.closingPrice - this.settlementConfig.serviceCharge)
      if (maxPrice > this.statistics.nowMoney) {
        return this.$Message.error('输入金额过大')
      }
      if (this.formRegist.password.length !== 6) {
        return this.$Message.error('密码输入不正确')
      }
      isSet()
          .then((res) => {
            this.xYVisible = false
            if (!res.data) {
              if (this.isGoogleBuild) {
                this.showFlag = 1
                this.payVisible = true
              } else {
                this.payMentGoogle()
              }
            } else {
              this.tipsOne = '未设置支付密码,前去设置?'
              this.showModel = true
            }
          })
    },
    showView() {
      this.formRegist = {
        coinaddress: '',
        network: 'USDT-TRC20',
        password: '',
        closingPrice: '',
      }
      this.getClosingConfig()
      this.getDefaultWalletAddress()
      this.isGoogleBuildQuery()
      this.xYVisible = true
    },
    isGoogleBuildQuery() {
      googleIsBuild()
          .then((res) => {
            this.isGoogleBuild = res.data
          })
    },
    maxClick() {
      this.formRegist.closingPrice = this.statistics.nowMoney
    },
    getDefaultWalletAddress() {
      defaultWalletAddress()
          .then(res => {
            if (res.data) {
              this.formRegist.coinaddress = res.data.address
            } else {
              this.formRegist.coinaddress = ''
            }
          })
    },
    getClosingConfig() {
      withdrawConfigApi()
          .then(res => {
            let data = res.data
            data.bankList.unshift('请选择币链名称')
            this.bankList = data.bankList
            this.settlementConfig = res.data
          })
    },
    getIssueList(type) {
      let data = {
        page: 1,
        limit: 100,
        issueType: type
      }
      queryNormalIssue(data)
          .then(res => {
            this.issueList = res.data.list
          })
    },
    tabsIndexClick(index) {
      this.tabsIndex = index
      if (index === 0) {
        this.getUserBillList()
      }
      if (index === 1) {
        this.getUserTagInfo()
        this.getIssueList(1)
      }
      if (index === 2) {
        this.page = 1
        this.getList()
        this.getIssueList(2)
      }
    },
    getUserTagInfo: function () {
      getUserInfo()
          .then(res => {
            let data = res.data
            this.userInfo = data
          })
    },
    selectMenu(e) {
      this.current[0] = e.key
      this.page = 1
      this.getUserBillList()
    },
    getList() {
      let that = this
      this.loading = true
      let recordListNew = []
      getClosingRecordApi({
        page: that.page,
        limit: that.limit
      })
          .then(res => {
            let list = res.data ? res.data : []
            for (let i = 0; i < list.length; i++) {
              let array1 = list[i].list
              for (let j = 0; j < array1.length; j++) {
                recordListNew.push(array1[j])
              }
            }
            this.recordList = recordListNew
            that.total = res.data.total
            that.loading = false
          })
    },
    userBalance() {
      getMyAccountApi()
          .then(res => {
            this.statistics = res.data
          })
    },
    // 分页 修改页码
    changePageNum(val) {
      this.page = val
      this.getUserBillList()
    },
    // 分页 修改页数
    changePageSize(val) {
      this.page = 1
      this.limit = val
      this.getUserBillList()
    },

    /**
     * 获取账户明细
     */
    getUserBillList() {
      let that = this
      that.loading = true
      let data = {
        page: that.page,
        limit: that.limit,
        type: that.current[0]
      }
      let listArray = []
      getBillList(data)
          .then((res) => {
            let list = res.data.list ? res.data.list : []
            for (let i = 0; i < list.length; i++) {
              let array1 = list[i].list
              for (let j = 0; j < array1.length; j++) {
                listArray.push(array1[j])
              }
            }
            this.userBillList = listArray
            that.total = res.data.total
            that.loading = false
          })
    },
  }
}
</script>

<style scoped lang="scss">
.ant-card:not {
  border-shadow: none;
}

.flex-title-view {
  width: 84px;
  height: 26px;
  border-radius: 50px;
  background: rgba(68, 68, 68, 0.6);

  font-size: 12px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;

  display: flex;
  justify-content: center;
  align-items: center;
}


.btn-all {
  cursor: pointer;
  width: 64px;
  height: 20px;
  border-radius: 15px;
  box-sizing: border-box;
  border: 1px solid #FF3F3F;

  color: #FF3F3F;

  display: flex;
  justify-content: center;
  align-items: center;

}

.btn-all-1 {
  cursor: pointer;
  width: 64px;
  height: 20px;
  border-radius: 15px;
  box-sizing: border-box;
  border: 1px solid #2b85e4;

  color: #2b85e4;

  display: flex;
  justify-content: center;
  align-items: center;
}

.usdt-balance {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #E5E5E5;

  .text-sm-font {
    font-size: 18px;

    .text-sm-font-1 {
      color: #E8E8E8;
      font-size: 12px;
    }
  }
}

.btn-add {
  border-color: #2b85e4 !important;
  color: #2b85e4 !important;
}

.input-cell {
  height: 40px;
  border: 1px solid #E5E5E5;
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}

.card-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: flex-start;

  .item-1 {
    width: 180px;
    height: 144px;

    display: flex;
    justify-content: center;
    align-items: center;
    background: #F8F9F8;
    border-radius: 12px;
  }

  .item-2-s {
    height: 160px;
    padding: 20px;

    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .item-title {
      font-size: 16px;
      font-weight: 900;
      line-height: 26px;
      letter-spacing: 0em;
    }

    .item-title-s {
      font-size: 13px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0em;
    }


    .item-number {
      margin-top: 13px;
      font-size: 32px;
      font-weight: bold;
      line-height: 20px;
      color: #FF3F3F;
      margin-left: -5px;
    }

    .item-number-s {
      margin-top: 13px;
      font-size: 18px;
      font-weight: bold;
      line-height: 20px;
      color: #FF3F3F;
      margin-left: -5px;
    }

    .item-unit-s {
      margin-top: 10px;
      font-size: 12px;
      font-weight: normal;
      line-height: 26px;
      letter-spacing: 0em;
      color: #C3C3C3;
    }

    .item-unit {
      margin-top: 10px;
      font-size: 16px;
      font-weight: normal;
      line-height: 26px;
      letter-spacing: 0em;
      color: #C3C3C3;
    }
  }

  .item-2 {
    height: 180px;
    padding: 30px;

    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .item-title {
      font-size: 16px;
      font-weight: 900;
      line-height: 26px;
      letter-spacing: 0em;
    }

    .item-title-s {
      font-size: 13px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0em;
    }


    .item-number {
      margin-top: 13px;
      font-size: 32px;
      font-weight: bold;
      line-height: 20px;
      color: #FF3F3F;
      margin-left: -5px;
    }

    .item-number-s {
      margin-top: 13px;
      font-size: 18px;
      font-weight: bold;
      line-height: 20px;
      color: #FF3F3F;
      margin-left: -5px;
    }

    .item-unit-s {
      margin-top: 10px;
      font-size: 12px;
      font-weight: normal;
      line-height: 26px;
      letter-spacing: 0em;
      color: #C3C3C3;
    }

    .item-unit {
      margin-top: 10px;
      font-size: 16px;
      font-weight: normal;
      line-height: 26px;
      letter-spacing: 0em;
      color: #C3C3C3;
    }
  }
}

</style>
