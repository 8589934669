<template>
  <div class="wrapper">
    <a-card class="cart-users" :loading="loading" :bodyStyle="{padding: '20px 40px'}">
      <template #title>
        USDT地址簿
      </template>
      <div>
        <a-button class="btn-add" @click="showModal">+ 新增地址</a-button>
      </div>
      <div class="mt-3">
        <empty v-if="walletAddress.length === 0"/>
        <a-card v-else class="mb-3" :bordered="true" v-for="(item, index) in  walletAddress" :key="index">
          <template #title>
            钱包名称: {{ item.addressName }}
          </template>
          <template #extra>
            <div class="flex">
              <div v-if="item.isChecked" class="text-red">默认地址</div>
              <div v-else class="cursor-pointer" @click="SwitchSex(index, item)">设置为默认地址</div>
            </div>
          </template>
          <div class="text-gray">
            <a-row :gutter="[16,24]">
              <a-col :span="3">地址:</a-col>
              <a-col :span="21">{{ item.address }}</a-col>
            </a-row>
            <a-row :gutter="[16,24]">
              <a-col :span="3">网络:</a-col>
              <a-col :span="21">{{ item.network }}</a-col>
            </a-row>
            <a-row :gutter="[16,24]">
              <a-col :span="3">创建时间:</a-col>
              <a-col :span="16">{{ item.createTime }}</a-col>
              <a-col :span="2" class="cu-center btn-cell">
                <div class="text-gray cursor-pointer" @click="del(item)">删除</div>
              </a-col>
              <a-col :span="2" class="btn-cell1 ml-6">
                <div class="text-blue cursor-pointer" @click="updateView(item)">修改</div>
              </a-col>
            </a-row>
          </div>
        </a-card>
      </div>
    </a-card>

    <a-modal v-model:open="xYVisible" title="添加地址" :width="820" :centered="true" :zIndex="10"
             @cancel="xYVisible = false">
      <div class="padding">
        <a-form :labelCol="{ style: { width: '120px' } }" :model="addWallet" @finish="onFinish">
          <a-form-item label="币种:" name="realName">
            <a-input class="input-cell" disabled v-model:value="addWallet.type" placeholder="请输入币种"/>
          </a-form-item>

          <a-form-item label="提币网络:" name="phone">
            <a-input class="input-cell" disabled placeholder="请输入提币网络" v-model:value="addWallet.network"/>
          </a-form-item>
          <a-form-item label="地址:" name="address1" :rules="[{ required: true, message: '请输入地址' }]">
            <a-input class="input-cell" placeholder="请输入地址" v-model:value="addWallet.address1"/>
          </a-form-item>
          <a-form-item label="地址名称(选填):" name="addressName1">
            <a-input class="input-cell" v-model:value="addWallet.addressName1" placeholder="请输入地址名称(选填)"
                     :max-length="20"/>
          </a-form-item>
          <a-form-item :labelCol="{ style: { width: '120px' } }">
            <a-button style="margin-left: 120px;width: 126px;" size="large" type="primary" html-type="submit">确认
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <template #footer>
      </template>
    </a-modal>

    <a-modal class="modal-view"
             v-model:open="modelVisible"
             z-index="10"
             :width="480"
             @cancel="modelVisible = false"
             :maskClosable="false">
      <template #title>
        安全验证
      </template>
      <div>
        <a-tabs v-model:activeKey="tabsIndex" @change="changeOrderStatus">
          <a-tab-pane :key="0" v-if="userInfo.email" :disabled="disabled">
            <template #tab>{{ $t('pwd.pwd_003') }}</template>
          </a-tab-pane>
          <a-tab-pane :key="1" v-if="userInfo.phone" :disabled="disabled">
            <template #tab>手机</template>
          </a-tab-pane>
        </a-tabs>
        <div>
          {{ tips }}
        </div>
        <div>
          <a-form>
            <a-form-item name="captcha" style="margin-bottom: 10px;margin-top: 10px">
              <a-input class="input-cell" v-model:value="captcha" :placeholder="$t('pwd.pwd_022')" :maxlength="6">
                <template #suffix>
                  <div class="cursor-pointer text-blue" @click="codeSend">
                    {{ text }}
                  </div>
                </template>
              </a-input>
            </a-form-item>
            <a-form-item>
              <div class="text-right mt-3">
                <a-button html-type="submit" type="primary" @click="checkCode">确认</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <template #footer/>
    </a-modal>
  </div>
</template>

<script>
import {
  cancelWalletAddress,
  addWalletAddress,
  updateWalletAddress,
  addressList,
  delWalletAddress,
  walletAddressInfo,
  checkedWalletAddress,
} from '@/api/wallet.js'

import {
  checkPaypwd,
  getUserInfo,
  sendCodeByPaypwd,
  checkCodeByAddress,
  userPwdInfo
} from '@/api/user'

import {
  Debounce
} from '@/utils/validate.js'

import sendVerifyCode from '@/utils/SendVerifyCode'

export default {
  name: 'Walletlist',
  data() {
    return {
      activeKey: 1,
      modelVisible: false,
      xYVisible: false,
      walletAddress: [],
      walletIndex: 1,
      addWallet: {
        type: 'USDT',
        network: 'USDT-TRC20',
        address: [],
        addressName: [],
        address1: '',
        addressName1: '',
        isChecked: false
      },
      withFlag: 0,
      modalName: null,
      listTouchStart: 0,
      listTouchDirection: null,
      tabsIndex: 0,
      itemIndex: {
        type: 'USDT',
        network: 'USDT-TRC20',
        address: [],
        addressName: [],
        isChecked: false
      },
      popUpshow: false,
      codeAccount: '',
      sendCodeFlag: 2,
      userInfo: {},
      captcha: '',
      loading: false,
      tips: '',
    }
  },
  mixins: [sendVerifyCode],
  mounted() {
    let user = localStorage.getItem('USER_INFO')
    if (user) {
      this.userInfo = JSON.parse(user)
    }
    if (this.$store.getters.token) {
      this.getAddressList()
      this.getLoginUserInfo()
    }
  },
  methods: {
    save() {
      this.loading = true
      addWalletAddress(this.addWallet)
          .then((res) => {
            if (res.code !== 200) {
              this.$Message.error(res.message)
            }
            if (res.data === 1) {
              this.step = 0
              this.walletIndex = 1
              this.addWallet = {
                type: 'USDT',
                network: 'USDT-TRC20',
                address1: '',
                addressName1: '',
                address: [],
                addressName: []
              }
              this.getAddressList()
            }
            this.loading = false
          })
    },
    checkCode() {
      let that_ = this
      if (!that_.captcha) {
        return
      }
      let data = {
        code: that_.captcha,
        sendCodeFlag: that_.sendCodeFlag
      }
      checkCodeByAddress(data)
          .then(res => {
            if (that_.step === 1) {
              that_.save()
            }
            if (that_.step === 2) {
              that_.update()
            }
            that_.captcha = ''
            this.modelVisible = false
          })
    },
    update() {
      this.loading = true
      updateWalletAddress(this.addWallet)
          .then(res => {
            if (res.code !== 200) {
              this.$Message.error(res.message)
            }
            this.addWallet = {
              type: 'USDT',
              network: 'USDT-TRC20',
              address1: '',
              addressName1: '',
              address: [],
              addressName: []
            }
            this.getAddressList()
            this.modelVisible = false

            this.loading = false
          })
    },
    codeSend() {
      let that = this
      if (that.disabled) return
      let data = {
        sendCodeFlag: this.sendCodeFlag
      }
      sendCodeByPaypwd(data)
          .then(res => {
            if(res.code === 200){
              that.sendCode()
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    changeOrderStatus(e) {
      if (e === 0) {
        this.tips = this.$t('pwd.pwd_008') +': ' + this.userInfo.email + this.$t('pwd.pwd_009')
      }
      if (e === 1) {
        this.tips = this.$t('pwd.pwd_008') +': +' + this.userInfo.countryCode + '  ' + this.userInfo.phone + ', ' + this.$t('pwd.pwd_024')
      }
    },
    showModal() {
      this.step = 1
      this.xYVisible = true
    },
    onFinish() {
      if (!this.addWallet.address1) {
        return this.$Message.error('请输入地址')
      }
      if (!this.addWallet.address1.startsWith('T') || this.addWallet.address1.length !== 34) {
        return this.$Message.error('地址不正确')
      }
      this.addWallet.address[0] = this.addWallet.address1
      this.addWallet.addressName[0] = this.addWallet.addressName1
      this.xYVisible = false
      this.modelVisible = true
    },
    updateView(item) {
      this.step = 2
      this.loading = false
      this.addWallet.id = item.id
      this.addWallet.type = 'USDT'
      this.addWallet.network = 'USDT-TRC20'
      this.addWallet.address1 = item.address
      this.addWallet.addressName1 = item.addressName
      this.addWallet.isChecked = item.isChecked
      this.xYVisible = true
    },
    SwitchSex(index, item) {
      this.walletAddress.forEach((items, indexItem) => {
        if (indexItem !== index) {
          this.checkedCancelWalletAddressed(item)
        } else {
          this.checkedWalletAddressed(items)
        }
      })
    },
    checkedCancelWalletAddressed(item) {
      cancelWalletAddress(item.id)
          .then(res => {
            this.getAddressList()
          })
    },
    checkedWalletAddressed(item) {
      checkedWalletAddress(item.id)
          .then(res => {
            this.getAddressList()
          })
    },
    getAddressList() {
      addressList()
          .then(res => {
            this.walletAddress = res.data
          })
    },
    del(item) {
      delWalletAddress(item.id)
          .then(res => {
            this.getAddressList()
          })
    },
    getLoginUserInfo() {
      getUserInfo()
          .then(res => {
            this.userInfo = res.data
            if (this.userInfo.email && this.userInfo.phone) {
              this.tips = this.$t('pwd.pwd_008') +': ' + this.userInfo.email + this.$t('pwd.pwd_009')
              this.tabsIndex = 0
              this.sendCodeFlag = 2
              this.codeAccount = this.userInfo.email
              return
            }
            if (this.userInfo.email) {
              this.sendCodeFlag = 2
              this.codeAccount = this.userInfo.email
              this.tips = this.$t('pwd.pwd_008') +': ' + this.userInfo.email + this.$t('pwd.pwd_009')
            }
            if (this.userInfo.phone) {
              this.sendCodeFlag = 1
              this.codeAccount = '+' + this.userInfo.countryCode + '  ' + this.userInfo.phone
            }
          })
    },
  }
}
</script>

<style scoped lang="scss">
.btn-cell {
  width: 67px;
  height: 26px;
  border-radius: 50px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #E4E4E4;
}

.btn-cell1 {
  width: 57px;
  height: 26px;
  border-radius: 50px;
  box-sizing: border-box;
  border: 1px solid #269FFA;

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-end;
}

.btn-add {
  border-color: #2b85e4 !important;
  color: #2b85e4 !important;
}

.input-cell {
  height: 40px;
  border: 1px solid #E5E5E5;
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>
